import { legacy_createStore, applyMiddleware, compose } from "redux";
import Thunk from "redux-thunk";
import reducers from "redux/reducers";

export function configureStore(initialState) {
  const store = legacy_createStore(reducers, initialState, compose(applyMiddleware(Thunk)));

  if (module.hot) {
    module.hot.accept("redux/reducers/index", () => {
      const nextRootReducer = require("redux/reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
