import React, { useState, useEffect } from "react";
import { RctCard, RctCardContent } from "components/RctCard";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ChaletTabs from "./ChaletTabs";
import PropertyTabs from "./PropertyTabs";
import ViewTabs from "./ViewTabs";
import ModalBodyForm from "./ModalBodyForm";
import api from "api";
function ProblemTrackerMainApp() {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [subPageNum, setSubPageNum] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchProblems();
  }, []);

  const fetchProblems = () => {
    setData([]);
    setLoading(true);
    api
      .get("/problems/list")
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        global.debugPrinter("Error:", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChaletChange = (e, v) => {
    setPageNum(v);
    setSubPageNum(0);
  };

  const handlePropertyChange = (e, v) => {
    setSubPageNum(v);
  };

  const handleOnUpdate = (originalRow, checked) => {
    api
      .put("/problems/update", {
        id: originalRow.id,
        isDone: checked,
      })
      .then((res) => {
        const newData = data.map((d) => {
          d.list = d.list.map((problem) => {
            if (problem.id === originalRow.id) {
              problem.isDone = checked;
            }
            return problem;
          });
          return d;
        });
        setData(newData);
      })
      .catch((e) => {
        global.debugPrinter("Error:", e);
      });
  };
  const handleOnDelete = (originalRow) => {
    api
      .delete(`/problems/delete/${originalRow.id}`)
      .then((res) => {
        if (res.data.done) {
          const d = data.map((chalet) => {
            chalet.list = chalet.list.filter((problem) => {
              return problem.id !== originalRow.id;
            });
            return chalet;
          });
          setData(d);
        }
      })
      .catch((e) => {
        global.debugPrinter("Error:", e);
      });
  };

  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSubmitProblem = (title, description, chalet, properties) => {
    const payload = {
      title,
      description,
      chalet,
      properties,
    };

    api
      .post("/problems/create", payload)
      .then((res) => {
        fetchProblems();
      })
      .catch((err) => {
        global.debugPrinter("ERROR", err);
      });
    // setShowModal(false);
  };

  return (
    <RctCard>
      {loading || data.length === 0 ? (
        <RctSectionLoader />
      ) : (
        <RctCardContent customClasses="justify-content-center">
          <ChaletTabs
            index={pageNum}
            data={data}
            onChaletChange={handleChaletChange}
            openAddModal={handleToggleModal}
          />
          <PropertyTabs
            data={data}
            chaletIndex={pageNum}
            propertyIndex={subPageNum}
            onPropertyChange={handlePropertyChange}
          />
          <ViewTabs
            index={subPageNum}
            data={data}
            pageNum={pageNum}
            onUpdate={handleOnUpdate}
            onDelete={handleOnDelete}
          />
          <Modal isOpen={showModal} centered toggle={handleToggleModal}>
            <ModalHeader
              close={
                <button className="close" onClick={handleToggleModal}>
                  ×
                </button>
              }
            >
              <div>Add Problem</div>
            </ModalHeader>
            <ModalBody>
              <ModalBodyForm submitForm={handleSubmitProblem} data={data} />
            </ModalBody>
          </Modal>
        </RctCardContent>
      )}
    </RctCard>
  );
}

export default ProblemTrackerMainApp;
