/**
 * Dasboard Routes
 */
import React from "react";
import { Route, Switch } from "react-router-dom";

// async components
import {
  AsyncDashboardRouteComponent,
  AsyncDashboardDetailsRouteComponent,
  AsyncSessionPage404Component,
} from "components/AsyncComponent/AsyncComponent";

const Dashboard = ({ match }) => {
  return (
    <div className="dashboard-wrapper">
      <Switch>
        <Route path={`${match.url}/`} component={AsyncDashboardRouteComponent} />
        <Route path={`${match.url}/details`} component={AsyncDashboardDetailsRouteComponent} />
        <Route path={`${match.url}/404`} component={AsyncSessionPage404Component} />
      </Switch>
    </div>
  );
};

export default Dashboard;
