//Receipts Pages
import Survey from "routes/Survey";
import SurveyGenerate from "routes/Survey/SurveyGenerate";
import SurveyReports from "routes/Survey/SurveyReports";
import SurveyTracker from "routes/Survey/SurveyTracker";
import SurveyView from "routes/Survey/SurveyView";
const routes = [
  {
    path: "survey",
    component: Survey,
  },
  {
    path: "generate",
    component: SurveyGenerate,
  },
  {
    path: "reports",
    component: SurveyReports,
  },
  {
    path: "tracker",
    component: SurveyTracker,
  },
  {
    path: ":surveyId",
    component: SurveyView,
  },
];

export default routes;
