import React, { Component } from "react";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import { RctCard, RctCardContent } from "components/RctCard";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import SweetAlert from "react-bootstrap-sweetalert";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";
import BasicInformationForm from "../BasicInformationForm";
import ChaletDetails from "../ChaletDetails";
import {
  isSunday,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
  nextSaturday,
  nextWednesday,
  nextThursday,
  nextSunday,
  format,
} from "date-fns";
import api from "api";
import { SUPER_ADMIN } from "constants/LocalStorageConfig";

const filter = createFilterOptions();
export default class index extends Component {
  state = {
    pageNum: 0,
    maxSteps: 0,
    id: 0,
    cashierName: "",
    customerInfo: { name: "", mobile: "" },
    customers: [],
    availableChalets: [],
    isDiscount: false,
    paymentType: "knet",
    discountReason: "",
    fullPrice: 400,
    insurancePrice: 100,
    depositPrice: 100,
    note: "",
    fromDate: null,
    toDate: null,
    showSuccessAlert: false,
    showFailedAlert: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const pState = { ...prevState };
    pState.pageNum = 0;
    pState.maxSteps = 0;
    const cState = { ...this.state };
    cState.pageNum = 0;
    cState.maxSteps = 0;
    if (JSON.stringify(cState) !== JSON.stringify(pState)) {
      this.setState({ maxSteps: this.state.pageNum });
    }
  }

  TabContent = ({ children }) => {
    return <div className="mt-2 p-20">{children}</div>;
  };

  componentDidMount() {
    global.printPageName("Receipts/CreateTemporary");
    this.initiateState();
  }

  initiateState = () => {
    this.setState({
      pageNum: 0,
      maxSteps: 0,
      id: 0,
      cashierName: "",
      customerInfo: { name: "", mobile: "" },
      customers: [],
      availableChalets: [],
      isDiscount: false,
      paymentType: "knet",
      discountReason: "",
      fullPrice: 400,
      insurancePrice: 100,
      depositPrice: 100,
      note: "",
      showSuccessAlert: false,
      showFailedAlert: false,
      fromDate: null,
      toDate: null,
      loading: true,
    });
    api
      .get("/receipts/create")
      .then((response) => {
        // handle success
        global.debugPrinter(response.data);
        this.setState(response.data);
        this.setState({ loading: false });
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };
  handlePageChange = (event, value) => {
    const maxSteps = value >= this.state.maxSteps ? value : this.state.maxSteps;
    this.setState({ pageNum: value, maxSteps });
  };

  handleTabClick = (event, value) => {
    if (value <= this.state.maxSteps) {
      this.handlePageChange(event, value);
    }
  };

  handleCustomerNameChange = (event, newValue) => {
    var customerInfo = newValue;
    if (typeof newValue === "string") {
      customerInfo = {
        name: newValue,
        mobile: this.state.customerInfo?.mobile,
      };
    } else if (newValue && newValue?.inputValue) {
      // Create a new value from the user input
      customerInfo = {
        name: newValue.inputValue,
        mobile: this.state.customerInfo?.mobile,
      };
    }
    if (customerInfo === null) {
      customerInfo = {
        name: "",
        mobile: "",
      };
    }
    this.setState({ customerInfo });
  };

  handleCustomerMobileChange = (event, newValue) => {
    var customerInfo = newValue;
    if (typeof newValue === "string") {
      customerInfo = {
        name: this.state.customerInfo?.name,
        mobile: newValue,
      };
    } else if (newValue && newValue?.inputValue) {
      // Create a new value from the user input
      customerInfo = {
        name: this.state.customerInfo?.name,
        mobile: newValue.inputValue,
      };
    }
    if (customerInfo === null) {
      customerInfo = {
        name: "",
        mobile: "",
      };
    }
    this.setState({ customerInfo });
  };

  //this could be moved as an internal function
  handleCustomerNameFilterOptions = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.title);
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        name: inputValue,
        mobile: this.state.customerInfo?.mobile,
      });
    }

    return filtered;
  };
  //this could be moved as an internal function
  handleCustomerMobileFilterOptions = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.title);
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        name: this.state.customerInfo?.name,
        mobile: inputValue,
      });
    }

    return filtered;
  };

  handleCustomerNameGetOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.name;
  };

  handleCustomerMobileGetOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.mobile;
  };

  handleSelectChalet = (index) => {
    const availableChalets = [...this.state.availableChalets];
    for (let i = 0; i < availableChalets.length; i++) {
      availableChalets[i].selected = false;
      for (let j = 0; j < availableChalets[i].available.length; j++) {
        availableChalets[i].available[j].selected = false;
      }
    }
    availableChalets[index].selected = true;
    this.setState(availableChalets);
  };

  handleSelectChaletProperty = (index) => {
    const availableChalets = [...this.state.availableChalets];
    const chalet = availableChalets.filter((chalet) => chalet.selected)[0];
    chalet.available[index].selected = !chalet.available[index].selected;
    this.setState(availableChalets);
  };
  handleFromDateChange = (date) => {
    let fromDate = date;
    let fromDateExtraLabel = null;
    let toDateExtraLabel = null;
    if (isMonday(fromDate) || isTuesday(fromDate) || isTuesday(fromDate) || isWednesday(fromDate)) {
      fromDate = nextThursday(fromDate);
      fromDateExtraLabel = `Date have changed from ${format(date, "dd/MM/yyyy")} to ${format(fromDate, "dd/MM/yyyy")}`;
    } else if (isFriday(fromDate) || isSaturday(fromDate)) {
      fromDate = nextSunday(fromDate);
      fromDateExtraLabel = `Date have changed from ${format(date, "dd/MM/yyyy")} to ${format(fromDate, "dd/MM/yyyy")}`;
    }
    let toDate = date;
    if (isSunday(fromDate)) {
      toDate = nextWednesday(fromDate);
    } else if (isThursday(fromDate)) {
      toDate = nextSaturday(fromDate);
    }
    const numOfProperties = this.getPropertiesLength();
    const numOfWeekDays = this.getNumberOfWeekDays(fromDate, toDate, "sunday");
    const numOfWeekends = this.getNumberOfWeekDays(fromDate, toDate, "thursday");
    const fullPrice = numOfWeekDays * 400 + numOfWeekends * 450 * numOfProperties;
    this.setState({ fromDate, toDate, fromDateExtraLabel, toDateExtraLabel, fullPrice });
  };
  handleToDateChange = (date) => {
    if (localStorage.getItem(SUPER_ADMIN) === `true`) {
      this.setState({ toDate: date });
      return;
    }
    let toDate = date;
    let toDateExtraLabel = null;
    if (isSunday(toDate) || isMonday(toDate) || isTuesday(toDate)) {
      toDate = nextWednesday(toDate);
      toDateExtraLabel = `Date have changed from ${format(date, "dd/MM/yyyy")} to ${format(toDate, "dd/MM/yyyy")}`;
    } else if (isThursday(toDate) || isFriday(toDate)) {
      toDate = nextSaturday(toDate);
      toDateExtraLabel = `Date have changed from ${format(date, "dd/MM/yyyy")} to ${format(toDate, "dd/MM/yyyy")}`;
    }
    const numOfProperties = this.getPropertiesLength();
    const numOfWeekDays = this.getNumberOfWeekDays(this.state.fromDate, toDate, "sunday");
    const numOfWeekends = this.getNumberOfWeekDays(this.state.fromDate, toDate, "thursday");
    const fullPrice = numOfWeekDays * 400 + numOfWeekends * 450 * numOfProperties;
    this.setState({ toDate, toDateExtraLabel, fullPrice });
  };

  getNumberOfWeekDays(start, end, dayNum) {
    if (!start || !end) return 0;
    // Sunday's num is 0 with Date.prototype.getDay.
    var days = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
    dayNum = days[dayNum.toLowerCase().substr(0, 3)];
    // Calculate the number of days between start and end.
    var daysInInterval = Math.ceil((end.getTime() - start.getTime()) / (1000 * 3600 * 24));
    // Calculate the nb of days before the next target day (e.g. next Sunday after start).
    var toNextTargetDay = (7 + dayNum - start.getDay()) % 7;
    // Calculate the number of days from the first target day to the end.
    var daysFromFirstTargetDay = Math.max(daysInInterval - toNextTargetDay, 0);
    // Calculate the number of weeks (even partial) from the first target day to the end.
    return Math.ceil(daysFromFirstTargetDay / 7);
  }

  getPropertiesLength = () => {
    return this.state.availableChalets
      .filter((chalet) => chalet.selected)[0]
      .available.filter((property) => {
        return property.selected;
      }).length;
  };
  getChalet = () => {
    return this.state.availableChalets.filter((chalet) => chalet.selected)[0].name;
  };

  getProperties = () => {
    return this.state.availableChalets
      .filter((chalet) => chalet.selected)[0]
      .available.filter((property) => property.selected)
      .map((res) => res.name)
      .join(" ");
  };

  handleSubmitTemporary = () => {
    const allInfo = this.state;
    const payload = {
      cashier: 2,
      customerInfo: allInfo.customerInfo,
      bookingDetails: {
        chalet: this.getChalet(),
        properties: this.getProperties(),
        fromDate: format(allInfo.fromDate, "dd/MM/yyyy"),
        toDate: format(allInfo.toDate, "dd/MM/yyyy"),
      },
    };
    global.debugPrinter("payload", payload);
    this.setState({ loading: true });
    api
      .post("/receipts/temp/create", payload)
      .then((response) => {
        global.debugPrinter(response.data);
        if (response.data > 0) {
          this.setState({ loading: false, showSuccessAlert: true });
        } else {
          this.setState({ loading: false, showFailedAlert: true });
        }
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };

  closeSweetAlert = () => {
    if (this.state.showSuccessAlert) {
      window.history.pushState({ path: "/receipts/create/temporary" }, "", "/receipts/create/temporary");
      this.initiateState();
      this.setState({ showSuccessAlert: false });
    } else {
      this.setState({ showFailedAlert: false });
    }
  };
  render() {
    const TabContent = this.TabContent;

    return (
      <div>
        <SweetAlert success show={this.state.showSuccessAlert} title="Success" onConfirm={this.closeSweetAlert} />
        <SweetAlert danger show={this.state.showFailedAlert} title="Failed" onConfirm={this.closeSweetAlert} />

        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="Transaction-table-wrap Tab-wrap">
              <RctCard>
                {this.state.loading && <RctSectionLoader />}

                <RctCardContent>
                  <AppBar position="static" color="default">
                    <SwipeableViews>
                      <Tabs
                        value={this.state.pageNum}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleTabClick}
                      >
                        <Tab label="Customer" />
                        <Tab label="Property" />
                      </Tabs>
                    </SwipeableViews>
                  </AppBar>

                  <form>
                    <SwipeableViews index={this.state.pageNum} onChangeIndex={this.handleChangeIndex}>
                      <TabContent>
                        <h1>Step 1 / 2</h1>
                        <BasicInformationForm
                          id={this.state.id}
                          cashierName={this.state.cashierName}
                          customerInfo={this.state.customerInfo}
                          customers={this.state.customers}
                          onChangeCustomerName={this.handleCustomerNameChange}
                          OnCustomerNameFilterOptions={this.handleCustomerNameFilterOptions}
                          OnCustomerNameGetOptionLabel={this.handleCustomerNameGetOptionLabel}
                          onChangeCustomerMobile={this.handleCustomerMobileChange}
                          OnCustomerMobileFilterOptions={this.handleCustomerMobileFilterOptions}
                          OnCustomerMobileGetOptionLabel={this.handleCustomerMobileGetOptionLabel}
                          onPageChange={this.handlePageChange}
                        />
                      </TabContent>
                      <TabContent>
                        <h1>Step 2 / 2</h1>
                        <ChaletDetails
                          availableChalets={this.state.availableChalets}
                          fromDate={this.state.fromDate}
                          fromDateExtraLabel={this.state.fromDateExtraLabel}
                          toDateExtraLabel={this.state.toDateExtraLabel}
                          toDate={this.state.toDate}
                          onSelectChalet={this.handleSelectChalet}
                          onSelectChaletProperty={this.handleSelectChaletProperty}
                          onFromDateChange={this.handleFromDateChange}
                          onToDateChange={this.handleToDateChange}
                          onNext={this.handleSubmitTemporary}
                          onPageChange={this.handlePageChange}
                          nextLabel="Submit"
                        />
                      </TabContent>
                    </SwipeableViews>
                  </form>
                </RctCardContent>
              </RctCard>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
