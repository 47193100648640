// sidebar nav links

const navlinks = {
  category1: [
    {
      menu_title: "Dashboard",
      menu_icon: "zmdi zmdi-view-dashboard",
      new_item: false,
      type_multi: null,
      child_routes: [
        {
          menu_title: "Dashboard",
          new_item: false,
          path: "/dashboard/index",
        },
        {
          menu_title: "Details",
          new_item: false,
          path: "/dashboard/details",
        },
      ],
    },
    {
      menu_title: "Receipts",
      menu_icon: "zmdi zmdi-receipt",
      new_item: false,
      type_multi: null,
      child_routes: [
        {
          menu_title: "Create Receipt",
          new_item: false,
          path: "/receipts/create",
        },
        {
          menu_title: "Create Temporary",
          new_item: false,
          path: "/receipts/create/temporary",
        },
        {
          menu_title: "View Receipts",
          new_item: false,
          path: "/receipts/view",
        },
        {
          menu_title: "View Temporary Receipts",
          new_item: false,
          path: "/receipts/view/temporary",
        },
        {
          menu_title: "Cancelled Receipts",
          new_item: false,
          path: "/receipts/view/cancelled",
        },
      ],
    },
    {
      menu_title: "Comments",
      menu_icon: "zmdi zmdi-comment-alt-text",
      new_item: false,
      type_multi: null,
      child_routes: [
        {
          menu_title: "Create a Comment",
          new_item: false,
          path: "/comments/create",
        },
        {
          menu_title: "View Comments",
          new_item: false,
          path: "/comments/view",
        },
      ],
    },
    {
      menu_title: "Extras",
      menu_icon: "zmdi zmdi-case",
      new_item: false,
      type_multi: null,
      child_routes: [
        {
          menu_title: "Insurance Management",
          new_item: false,
          path: "/extras/insurance",
        },
        {
          menu_title: "Notification Center",
          new_item: false,
          path: "/extras/notifications",
        },
        {
          menu_title: "Problem Tracker",
          new_item: false,
          path: "/extras/problemTracker",
        },
        {
          menu_title: "Customers Info",
          new_item: false,
          path: "/extras/customers",
        },
        {
          menu_title: "Logger",
          new_item: false,
          path: "/extras/logger",
        },
      ],
    },
    {
      menu_title: "Survey",
      menu_icon: "material-icons",
      menu_icon_name: "how_to_vote",
      new_item: false,
      type_multi: null,
      child_routes: [
        // {
        //   menu_title: "Generate",
        //   new_item: false,
        //   path: "/survey/generate",
        // },
        {
          menu_title: "Reports",
          new_item: false,
          path: "/survey/reports",
        },
        {
          menu_title: "Survey Tracker",
          new_item: false,
          path: "/survey/tracker",
        },
      ],
    },
    {
      menu_title: "Calendar",
      menu_icon: "zmdi zmdi-calendar-note",
      new_item: false,
      type_multi: null,
      path: "/calendar",
    },
  ],
};

export default navlinks;
