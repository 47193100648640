// Date Picker
import React, { Fragment } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";

export default React.memo(({ label, value, onDateChange, extra }) => {
  return (
    <Fragment>
      <div className="rct-picker">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label={label}
            value={value || null}
            onChange={onDateChange}
            minDate={new Date().toString()}
            animateYearScrolling={false}
            leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
            rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
            fullWidth
          />
        </MuiPickersUtilsProvider>
        {extra && <p className="text-primary">{extra}</p>}
      </div>
    </Fragment>
  );
});
