import React, { Component } from "react";
import { RctCard, RctCardContent } from "components/RctCard";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";
import api from "api";

export default class index extends Component {
  state = {
    actionsModal: false,
  };
  componentDidMount() {
    global.printPageName("Comments/View");
    this.setState({ loading: true });
    api
      .get("/comments/list")
      .then((response) => {
        global.debugPrinter(response.data);
        this.setState({ loading: false });
        this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  }

  handleCancel = (data) => {
    this.setState({ loading: true });
    api
      .delete("/comments/" + data.id)
      .then((response) => {
        global.debugPrinter(response.data);
        var filtered = this.state.data;
        if (response.data) {
          filtered = filtered.filter((row) => row.id !== data.id);
        }
        this.setState({ loading: false, data: filtered });
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };
  columns = [
    {
      name: "id",
      label: " ",
      options: {
        customBodyRender: (data, type, row) => {
          let originalRow = this.state.data.find((o) => {
            return o.id === data;
          });
          const btnStyle = {
            MozUserSelect: "none",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            OUserSelect: "none",
            userSelect: "none",
            cursor: "pointer",
          };
          const btnClasses = "material-icons text-secondary ";
          return (
            <div className="row d-flex align-items-center justify-content-center" style={{ width: 60, minHeight: 50 }}>
              <span className={btnClasses} style={btnStyle} onClick={() => this.handleCancel(originalRow)}>
                close
              </span>
            </div>
          );
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: "chalet",
      label: "Chalet",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150, maxWidth: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "comment",
      label: "Comment",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ minWidth: isMobile ? 200 : 800 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "addedBy",
      label: "added By",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150, maxWidth: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
  ];

  options = {
    filterType: "dropdown",
    fixedHeader: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    responsive: "standard",
    elevation: 0,
    download: false,
    print: false,
    viewColumns: false,
  };

  getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableCell: {
          head: {
            zIndex: "0 !important",
          },
        },
      },
    });

  render() {
    return (
      <div>
        <RctCard>
          {this.state.loading && <RctSectionLoader />}
          <RctCardContent noPadding>
            <ThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable
                theme={this.theme}
                className="table table-striped "
                title={"Available Comments"}
                data={this.state.data}
                columns={this.columns}
                options={this.options}
              />
            </ThemeProvider>
          </RctCardContent>
        </RctCard>
      </div>
    );
  }
}
