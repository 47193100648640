import React, { useState, useEffect } from "react";
import api from "api";
import { RctCard, RctCardContent } from "components/RctCard";
import { LoggerTable } from "./LoggerTable";

const Logger = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchProblems();
  }, []);

  const fetchProblems = () => {
    setLoading(true);
    api
      .get("/logger/list")
      .then((res) => {
        setData(res.data);
        console.log(res.data);
      })
      .catch((e) => {
        global.debugPrinter("Error:", e);
        console.log("Error:", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <RctCard>
      <RctCardContent>
        <LoggerTable data={data} />
      </RctCardContent>
    </RctCard>
  );
};

export default Logger;
