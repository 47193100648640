/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import React from "react";
import Loadable from "react-loadable";

// rct page loader
import RctPageLoader from "components/RctPageLoader/RctPageLoader";

// ecommerce dashboard
const AsyncDashboardRouteComponent = Loadable({
  loader: () => import("routes/dashboard/dashboard"),
  loading: () => <RctPageLoader />,
});

// ecommerce dashboard
const AsyncDashboardDetailsRouteComponent = Loadable({
  loader: () => import("routes/dashboard/details"),
  loading: () => <RctPageLoader />,
});

// ecommerce dashboard
const AsyncViewReceiptsComponent = Loadable({
  loader: () => import("routes/Receipts/View"),
  loading: () => <RctPageLoader />,
});

// Session Page 404
const AsyncSessionPage404Component = Loadable({
  loader: () => import("routes/404"),
  loading: () => <RctPageLoader />,
});
export {
  AsyncDashboardRouteComponent,
  AsyncDashboardDetailsRouteComponent,
  AsyncViewReceiptsComponent,
  AsyncSessionPage404Component,
};
