import React, { Component } from "react";
import { RctCard, RctCardContent } from "components/RctCard";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";
import api from "api";
import AppConfig from "constants/AppConfig";
import { compareAsc, compareDesc, parse } from "date-fns";

export default class index extends Component {
  state = {
    data: [],
  };
  componentDidMount() {
    global.printPageName("Receipts/CancelledReceipts");
    this.setState({ loading: true });
    api
      .get("/receipts/cancelled/list")
      .then((response) => {
        global.debugPrinter(response.data.data);
        this.setState({ loading: false });
        this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  }

  columns = [
    {
      name: "id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "90px" } }),
        customBodyRender: (data, type, row) => {
          let originalRow = this.state.data.find((o) => {
            return o.id === data;
          });
          const btnStyle = {
            MozUserSelect: "none",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            OUserSelect: "none",
            userSelect: "none",
            cursor: "pointer",
          };
          const btnClasses = "material-icons text-secondary mr-1";
          return (
            <div className="row d-flex align-items-center justify-content-center" style={{ width: 60, minHeight: 50 }}>
              <span
                className={btnClasses}
                style={btnStyle}
                onClick={() => this.download(AppConfig.apiUrl + originalRow.downloadLink)}
              >
                download
              </span>
            </div>
          );
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "chalet",
      label: "Chalet",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "period",
      label: "Period",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 200 }}>
              <span>{data}</span>
            </div>
          );
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            var date1 = obj1.data;
            date1 = date1.substring(0, date1.indexOf(" "));
            var date2 = obj2.data;
            date2 = date2.substring(0, date2.indexOf(" "));
            const dateLeft = parse(date1, "dd/MM/yyyy", new Date());
            const dateRight = parse(date2, "dd/MM/yyyy", new Date());
            return order === "asc" ? compareAsc(dateLeft, dateRight) : compareDesc(dateLeft, dateRight);
          };
        },
      },
    },
    {
      name: "amounts",
      label: "Amount",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 120 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "cashier",
      label: "Cashier",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },

    {
      name: "note",
      label: "Note",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 200 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
  ];

  options = {
    filterType: "dropdown",
    fixedHeader: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    responsive: "standard",
    elevation: 0,
    download: false,
    print: false,
    viewColumns: false,
    sortOrder: {
      name: "period",
      direction: "desc",
    },
  };

  getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableCell: {
          head: {
            zIndex: "0 !important",
          },
        },
      },
    });
  download = (data) => {
    window.open(data, "_self");
  };

  render() {
    return (
      <div>
        <RctCard>
          {this.state.loading && <RctSectionLoader />}

          <RctCardContent noPadding>
            <ThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable
                theme={this.theme}
                className="table table-striped "
                title={"Cancelled Receipts"}
                data={this.state.data}
                columns={this.columns}
                options={this.options}
              />
            </ThemeProvider>
          </RctCardContent>
        </RctCard>
      </div>
    );
  }
}
