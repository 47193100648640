//Comments Pages
import Login from "routes/Authentication/Login";
import Register from "routes/Authentication/Register";

const routes = [
  //Dashboard
  {
    path: "login",
    component: Login,
  },
  {
    path: "register",
    component: Register,
  },
];

export default routes;
