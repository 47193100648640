import React, { useState, useEffect, useCallback } from "react";
import { Label } from "reactstrap";
import InputMUI from "@material-ui/core/Input";
import InputLabelMUI from "@material-ui/core/InputLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import api from "api";
const ModalBodyForm = ({ submitForm }) => {
  const [availableChalets, setAvailableChalets] = useState([]);
  const [isValid, setValidSubmit] = useState(true);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    api
      .get("/general/availableChalets")
      .then((response) => {
        // global.debugPrinter(response.data);
        setAvailableChalets(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
      });
  }, []);

  const handleSelectChalet = (index) => {
    const available = [...availableChalets];
    for (let i = 0; i < available.length; i++) {
      available[i].selected = false;
      for (let j = 0; j < available[i].available.length; j++) {
        available[i].available[j].selected = false;
      }
    }
    available[index].selected = true;
    setAvailableChalets(available);
  };

  const handleSelectChaletProperty = (index) => {
    const available = [...availableChalets];
    const chalet = available.filter((chalet) => chalet.selected)[0];
    // chalet.available.map((property) => (property.selected = false));
    chalet.available[index].selected = !chalet.available[index].selected;
    setAvailableChalets(available);
  };

  const getChalet = useCallback(() => {
    return availableChalets?.filter((chalet) => chalet.selected)?.[0]?.name;
  }, [availableChalets]);

  const getProperties = useCallback(() => {
    return availableChalets
      ?.filter((chalet) => chalet.selected)?.[0]
      ?.available.filter((property) => property.selected)
      ?.map((res) => res.name)
      ?.join(" ");
  }, [availableChalets]);

  useEffect(() => {
    if (getChalet()?.length > 0 && getProperties()?.length > 0 && title.length > 0 && description.length > 0) {
      setValidSubmit(true);
    } else {
      setValidSubmit(false);
    }
  }, [availableChalets, title, description, getChalet, getProperties]);

  return (
    <>
      <FormControl fullWidth>
        <InputLabelMUI htmlFor="name-disabled">Title</InputLabelMUI>
        <InputMUI
          id="name-disabled"
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth aria-describedby="name-helper-text" className="mt-4">
        <InputLabel htmlFor="name-helper">Description</InputLabel>
        <InputMUI
          id="name-helper"
          value={description}
          onChange={(event) => {
            setDescription(event.target.value);
          }}
        />
      </FormControl>

      <FormControl disabled fullWidth className="mt-4">
        <Label htmlFor="chaletChoice" className="MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
          Chalet Choice
        </Label>
        <div className="btn-group mt-0" role="group" id="chaletChoice">
          {availableChalets.map((chalet, index) => {
            return (
              <button
                key={`id-${index}`}
                type="button"
                className={`btn col ${chalet.selected && "btn-primary"}`}
                onClick={() => {
                  handleSelectChalet(index);
                }}
              >
                {chalet.name}
              </button>
            );
          })}
        </div>
      </FormControl>

      <FormControl disabled fullWidth className="mt-4">
        <Label
          htmlFor="chaletPropertyChoice"
          className="MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
        >
          Chalet Property
        </Label>
        <div className="btn-group" role="group" id="chaletPropertyChoice">
          {availableChalets
            .filter((chalet) => chalet.selected)
            .map((chalet) => {
              return chalet.available.map((property, index) => {
                return (
                  <button
                    key={`id-sub-${index}`}
                    type="button"
                    className={`btn col ${property.selected && "btn-primary"}`}
                    onClick={() => {
                      handleSelectChaletProperty(index);
                    }}
                  >
                    {property.name}
                  </button>
                );
              });
            })}
        </div>
      </FormControl>

      <button
        className="btn btn-primary mt-4 btn-block"
        onClick={() => submitForm(title, description, getChalet(), getProperties())}
        disabled={!isValid}
      >
        Submit
      </button>
    </>
  );
};

export default ModalBodyForm;
