import React from "react";
import { ResponsiveContainer, RadarChart, PolarAngleAxis, PolarRadiusAxis, PolarGrid, Radar, Legend } from "recharts";
import { Tooltip } from "recharts";
const FeedbackRadarChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={290}>
      <RadarChart outerRadius={100} data={data?.data}>
        <PolarGrid />
        <PolarAngleAxis dataKey="name" />
        <PolarRadiusAxis angle={50} domain={[0, 2]} />
        {data?.keys?.map((d) => {
          return (
            <Radar
              name={d.value}
              dataKey={"value." + d.theKey}
              stroke={d.color}
              fill={d.color}
              fillOpacity={0.6}
              key={d.value}
            />
          );
        })}
        <Legend />
        <Tooltip />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default FeedbackRadarChart;
