/**
 * Rct Collapsible Card
 */
import React, { Component } from "react";
import { Collapse, Badge } from "reactstrap";
import classnames from "classnames";

// rct section loader
import RctSectionLoader from "../RctSectionLoader/RctSectionLoader";

class RctCollapsibleCard extends Component {
  state = {
    reload: false,
    collapse: true,
    close: false,
  };

  onCollapse() {
    this.setState({ collapse: !this.state.collapse });
  }

  onReload() {
    this.setState({ reload: true });
    let self = this;
    setTimeout(() => {
      self.setState({ reload: false });
    }, 1500);
  }

  onCloseSection() {
    this.setState({ close: true });
  }
  onDownload(link) {
    window.open(link, "_self");
  }

  render() {
    const { close, reload, collapse } = this.state;
    const {
      children,
      collapsible,
      closeable,
      reloadable,
      heading,
      fullBlock,
      colClasses,
      customClasses,
      headingCustomClasses,
      contentCustomClasses,
      badge,
      downloadable,
      downloadLink,
    } = this.props;

    return (
      <div className={classnames(colClasses ? colClasses : "", { "d-block": !collapse })}>
        <div className={classnames(`rct-block ${customClasses ? customClasses : ""}`, { "d-none": close })}>
          {heading && (
            <div className={`rct-block-title ${headingCustomClasses ? headingCustomClasses : ""}`}>
              <h4>
                {heading}{" "}
                {badge && (
                  <Badge className="p-1 ml-10" color={badge.class}>
                    {badge.name}
                  </Badge>
                )}
              </h4>
              {(collapsible || reloadable || closeable || downloadable) && (
                <div className="contextual-link">
                  {collapsible && (
                    <a href="_" onClick={() => this.onCollapse()}>
                      <i className="ti-minus"></i>
                    </a>
                  )}
                  {reloadable && (
                    <a href="_" onClick={() => this.onReload()}>
                      <i className="ti-reload"></i>
                    </a>
                  )}
                  {closeable && (
                    <a href="_" onClick={() => this.onCloseSection()}>
                      <i className="ti-close"></i>
                    </a>
                  )}
                  {downloadable && (
                    <div onClick={() => window.open(downloadLink, "_self")}>
                      <i className="zmdi zmdi-download" style={{ fontSize: 20 }}></i>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <Collapse isOpen={collapse}>
            <div
              className={classnames(contentCustomClasses ? contentCustomClasses : "", {
                "rct-block-content": !fullBlock,
                "rct-full-block": fullBlock,
              })}
            >
              {children}
            </div>
          </Collapse>
          {reload && <RctSectionLoader />}
        </div>
      </div>
    );
  }
}

export default RctCollapsibleCard;
