import React from "react";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Label } from "reactstrap";
import Switch from "@material-ui/core/Switch";
import { SUPER_ADMIN } from "constants/LocalStorageConfig";

const PaymentDetails = ({
  paymentType,
  onDiscount,
  isDiscount,
  onPaymentTypeChange,
  discountReason,
  onEditDiscountReason,
  fullPrice,
  insurancePrice,
  depositPrice,
  onEditFullPrice,
  onEditInsurancePrice,
  onEditDepositPrice,
  onPageChange,
}) => {
  const knet = paymentType === "knet";
  const cash = paymentType === "cash";
  const isDisabled = () => {
    if (localStorage.getItem(SUPER_ADMIN) === `true`) {
      return false;
    }
    const emptyPrice = fullPrice === "0" || fullPrice === null || fullPrice === "";
    const emptyInsurance = insurancePrice === "0" || insurancePrice === null || insurancePrice === "";
    const emptyDeposit = depositPrice === "0" || depositPrice === null || depositPrice === "";
    const emptyDiscountReason = isDiscount && discountReason.length === 0;
    return emptyPrice || emptyInsurance || emptyDeposit || emptyDiscountReason;
  };
  return (
    <div>
      <div className="row row-eq-height">
        <div className="col-lg-6 col-sm-12 col-md-6 w-xs-half-block">
          <p />
          <FormControl fullWidth>
            <InputLabel htmlFor="amount">Full Price</InputLabel>
            <Input
              type="number"
              id="adornment-amount"
              value={`${fullPrice}`}
              onChange={onEditFullPrice}
              startAdornment={<InputAdornment position="start">KD</InputAdornment>}
            />
          </FormControl>
          <FormControl fullWidth className="mt-4">
            <InputLabel htmlFor="insurance">Insurance Price</InputLabel>
            <Input
              type="number"
              id="adornment-insurance"
              value={insurancePrice}
              onChange={onEditInsurancePrice}
              startAdornment={<InputAdornment position="start">KD</InputAdornment>}
            />
          </FormControl>
          <FormControl fullWidth className="mt-4">
            <InputLabel htmlFor="deposit">Deposit Price</InputLabel>
            <Input
              type="number"
              id="adornment-deposit"
              value={depositPrice}
              onChange={onEditDepositPrice}
              startAdornment={<InputAdornment position="start">KD</InputAdornment>}
            />
          </FormControl>
        </div>
        <div className="  col-sm-12 col-md-6 w-xs-half-block">
          <FormControl disabled fullWidth className="mb-4">
            <Label htmlFor="chaletChoice" className="MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
              Payment Type
            </Label>
            <div className="btn-group " role="group" id="chaletChoice">
              <button type="button" className={`btn col ${knet && "btn-primary"}`} onClick={onPaymentTypeChange}>
                K-net
              </button>
              <button type="button" className={`btn col ${cash && "btn-primary"}`} onClick={onPaymentTypeChange}>
                Cash
              </button>
            </div>
          </FormControl>
          <FormControl fullWidth aria-describedby="name-helper-text">
            <span>
              <Label>Discount? </Label>
              <Switch checked={isDiscount} onClick={onDiscount} color="primary" className="switch-btn  " />
            </span>
          </FormControl>
          {isDiscount ? (
            <div>
              <p />
              <FormControl fullWidth aria-describedby="name-helper-text">
                <InputLabel htmlFor="name-helper">Discount Reason</InputLabel>
                <Input id="name-helper" value={discountReason} onChange={onEditDiscountReason} />
              </FormControl>
            </div>
          ) : (
            <div>
              <p />
              <p />
              <p />
              <p />
              <p />
            </div>
          )}
        </div>
      </div>
      <div className="d-flex flex-row-reverse justify-content-between">
        <button
          className="btn btn-primary mt-4"
          disabled={isDisabled()}
          onClick={() => {
            onPageChange(null, 3);
          }}
          type="button"
        >
          Next
        </button>

        <button
          className="btn btn-primary mt-4"
          onClick={() => {
            onPageChange(null, 1);
          }}
          type="button"
        >
          previous
        </button>
      </div>
    </div>
  );
};

export default PaymentDetails;
