import React, { Component } from "react";
import { RctCard, RctCardContent } from "components/RctCard";
import FormControl from "@material-ui/core/FormControl";
import { Label, Input } from "reactstrap";
import api from "api";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";
import SweetAlert from "react-bootstrap-sweetalert";

export default class CreateComment extends Component {
  state = {
    availableChalets: [],
    comment: "",
    showSuccessAlert: false,
  };

  componentDidMount() {
    global.printPageName("Comments/Create");
  }
  handleSelectChalet = (index) => {
    const availableChalets = [...this.state.availableChalets];
    for (let i = 0; i < availableChalets.length; i++) {
      availableChalets[i].selected = false;
      for (let j = 0; j < availableChalets[i].available.length; j++) {
        availableChalets[i].available[j].selected = false;
      }
    }
    availableChalets[index].selected = true;
    this.setState(availableChalets);
  };
  getChalet = () => {
    return this.state.availableChalets?.filter((chalet) => chalet.selected)?.[0]?.name;
  };

  getProperties = () => {
    return this.state.availableChalets
      ?.filter((chalet) => chalet.selected)?.[0]
      ?.available.filter((property) => property.selected)
      ?.map((res) => res.name)
      ?.join(" ");
  };

  handleSelectChaletProperty = (index) => {
    const availableChalets = [...this.state.availableChalets];
    const chalet = availableChalets.filter((chalet) => chalet.selected)[0];
    chalet.available.map((property) => (property.selected = false));
    chalet.available[index].selected = !chalet.available[index].selected;
    this.setState(availableChalets);
  };
  handleCommentChange = (e) => {
    const comment = e.target.value;
    this.setState({ comment });
  };
  handlePostComment = () => {
    this.setState({ loading: true });
    const payload = {
      comment: this.state.comment,
      addedBy: 1,
      chalet: this.getChalet(),
      property: this.getProperties(),
    };
    api
      .post("/comments/create", payload)
      .then((response) => {
        global.debugPrinter(response.data);
        this.setState({ loading: false, showSuccessAlert: true });
        // this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };

  isDisabled = () => {
    return this.getChalet()?.length === 0 || this.getProperties()?.length === 0 || this.state.comment.length === 0;
  };

  closeSweetAlert = () => {
    const availableChalets = [...this.state.availableChalets];
    for (let i = 0; i < availableChalets.length; i++) {
      for (let j = 0; j < availableChalets[i].available.length; j++) {
        availableChalets[i].available[j].selected = false;
      }
    }
    this.setState({ showSuccessAlert: false, comment: "", availableChalets });
  };
  render() {
    const { availableChalets } = this.state;
    return (
      <div>
        <h1>Register</h1>
        <SweetAlert success show={this.state.showSuccessAlert} title="Success" onConfirm={this.closeSweetAlert} />
        <RctCard>
          {this.state.loading && <RctSectionLoader />}
          <RctCardContent customClasses="justify-content-center ">
            <form>
              <div className="row row-eq-height">
                <div className="col-lg-6 col-sm-12 col-md-6 w-xs-half-block">
                  <FormControl disabled fullWidth>
                    <Label
                      htmlFor="chaletChoice"
                      className="MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
                    >
                      Chalet Choice
                    </Label>
                    <div className="btn-group mt-0" role="group" id="chaletChoice">
                      {availableChalets.map((chalet, index) => {
                        return (
                          <button
                            key={`id-${index}`}
                            type="button"
                            className={`btn col ${chalet.selected && "btn-primary"}`}
                            onClick={() => {
                              this.handleSelectChalet(index);
                            }}
                          >
                            {chalet.name}
                          </button>
                        );
                      })}
                    </div>
                  </FormControl>
                  <FormControl disabled fullWidth className="mt-4">
                    <Label
                      htmlFor="chaletPropertyChoice"
                      className="MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
                    >
                      Chalet Property
                    </Label>
                    <div className="btn-group" role="group" id="chaletPropertyChoice">
                      {availableChalets
                        .filter((chalet) => chalet.selected)
                        .map((chalet) => {
                          return chalet.available.map((property, index) => {
                            return (
                              <button
                                key={`id-sub-${index}`}
                                type="button"
                                className={`btn col ${property.selected && "btn-primary"}`}
                                onClick={() => {
                                  this.handleSelectChaletProperty(index);
                                }}
                              >
                                {property.name}
                              </button>
                            );
                          });
                        })}
                    </div>
                  </FormControl>
                </div>
                <div className="col-lg-6 col-sm-12 col-md-6 w-xs-half-block">
                  <FormControl fullWidth>
                    <Label htmlFor="comment">Comment</Label>
                    <Input
                      type="textarea"
                      id="adornment-comment"
                      value={this.state.comment}
                      onChange={this.handleCommentChange}
                      style={{ height: 180 }}
                    />
                  </FormControl>
                </div>
              </div>
            </form>
            <div className="d-flex flex-row-reverse justify-content-between">
              <button
                className="btn btn-primary mt-4"
                onClick={this.handlePostComment}
                type="button"
                disabled={this.isDisabled()}
              >
                Submit
              </button>
            </div>
          </RctCardContent>
        </RctCard>
      </div>
    );
  }
}
