import React, { useState, useEffect } from "react";
import { RctCard, RctCardContent } from "components/RctCard";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";

import api from "api";
import TableTracker from "./TableTracker";

const SurveyTracker = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    api
      .get("/survey/tracker")
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <RctCard>
      {loading && <RctSectionLoader />}
      <RctCardContent>
        <TableTracker data={data} />
      </RctCardContent>
    </RctCard>
  );
};

export default SurveyTracker;
