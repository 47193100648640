import React, { Component } from "react";
import { RctCard, RctCardContent } from "components/RctCard";
import MUIDataTable from "mui-datatables";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import api from "api";
import SweetAlert from "react-bootstrap-sweetalert";
import AppConfig from "constants/AppConfig";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";
import { compareAsc, compareDesc, parse } from "date-fns";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default class index extends Component {
  state = {
    actionsModal: false,
    earlyReceiptModal: false,
    loading: true,
    showSuccessAlert: false,
    showFailedAlert: false,
    successAlertWhatsapp: "",
    successAlertSurvey: "",
  };
  componentDidMount() {
    global.printPageName("Receipts/View");
    this.initiateState();
  }

  closeSweetAlert = () => {
    this.setState({
      showSuccessAlert: false,
      showFailedAlert: false,
      successAlertWhatsapp: "",
      successAlertSurvey: "",
    });
  };

  initiateState = () => {
    this.setState({ loading: true });
    api
      .get("/receipts/list")
      .then((response) => {
        global.debugPrinter(response.data);
        this.setState({ loading: false });
        this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };

  generateSurvey = (id) => {
    this.setState({ loading: true });
    api
      .get(`/survey/generate/${id}`)
      .then((response) => {
        console.log("DATA:", response.data, id);
        const newData = this.state.data.map((row) => {
          if (row.id === id) {
            row.control.surveyEnable = false;
          }
          return row;
        });
        this.setState({
          loading: false,
          showSuccessAlert: true,
          successAlertWhatsapp: response.data.whatsapp,
          successAlertSurvey: response.data.survey,
          data: newData,
        });
      })
      .catch((error) => {
        // handle error
        global.debugPrinter("error", error);
        this.setState({ loading: false, showFailedAlert: true });
      });
  };
  columns = [
    {
      name: "status",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "10px" } }),
        customBodyRender: (data, type, row) => {
          const badge = data ? " badge-success" : " badge-danger";
          return <span className={`badge badge-xs position-relative mr-1 ${badge}`}>&nbsp;</span>;
        },
        filter: false,
        sort: false,
        column: false,
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        setCellProps: () => ({ style: { width: "90px" } }),
        customBodyRender: (data, type, row) => {
          let originalRow = this.state.data.find((o) => {
            return o.id === data;
          });
          originalRow["earlyPrice"] = 50;
          const btnStyle = {
            MozUserSelect: "none",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            OUserSelect: "none",
            userSelect: "none",
            cursor: "pointer",
          };
          const btnClasses = "material-icons text-secondary mr-1";
          const control = originalRow?.control;
          const done = control?.editable || control?.cancelable || control?.earlyEntrance || control?.completable;
          return (
            <div className="row d-flex align-items-center" style={{ width: 95, minHeight: 50 }}>
              {done && (
                <span className={btnClasses} style={btnStyle} onClick={() => this.actionsModal(originalRow)}>
                  done
                </span>
              )}
              <span
                className={btnClasses}
                style={btnStyle}
                onClick={() => this.download(AppConfig.apiUrl + originalRow.downloadLink)}
              >
                download
              </span>
              {control?.surveyEnable && (
                <span className={btnClasses} style={btnStyle} onClick={() => this.generateSurvey(data)}>
                  how_to_vote
                </span>
              )}
            </div>
          );
        },
        // filter: false,
        // sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "chalet",
      label: "Chalet",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "period",
      label: "Period",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 200 }}>
              <span>{data}</span>
            </div>
          );
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            var date1 = obj1.data;
            date1 = date1.substring(0, date1.indexOf(" "));
            var date2 = obj2.data;
            date2 = date2.substring(0, date2.indexOf(" "));
            const dateLeft = parse(date1, "dd/MM/yyyy", new Date());
            const dateRight = parse(date2, "dd/MM/yyyy", new Date());
            return order === "asc" ? compareAsc(dateLeft, dateRight) : compareDesc(dateLeft, dateRight);
          };
        },
      },
    },
    {
      name: "amounts",
      label: "Amount",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 120 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "cashier",
      label: "Cashier",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "note",
      label: "Note",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 200 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "discountReason",
      label: "Discount Reason",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 200 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
  ];

  options = {
    filterType: "dropdown",
    fixedHeader: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    responsive: "standard",
    elevation: 0,
    download: false,
    print: false,
    viewColumns: false,
    sortOrder: {
      name: "period",
      direction: "desc",
    },
  };

  getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableCell: {
          head: {
            zIndex: "0 !important",
          },
        },
      },
    });

  download = (data) => {
    window.open(data, "_self");
  };

  actionsModal = (data) => {
    if (data) {
      this.setState({ modelData: data, actionsModal: true });
    } else {
      this.setState({ modelData: null, actionsModal: false });
    }
  };

  handleCancelTemporaryBooking = () => {
    const data = this.state.modelData;
    global.debugPrinter("handleCancelTemporaryBooking", data.id);
    api
      .get("/receipts/temp/cancel/" + data.id)
      .then((response) => {
        global.debugPrinter("Cancel temporary booking", response.data);
        // this.setState({ loading: false });
        // this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
    // Call Api to cancel booking refetch data or remove it from the array
  };

  handleEditBooking = () => {
    const data = this.state.modelData;
    global.debugPrinter("handleEditBooking22", data.id);
    const url = "/receipts/create?id=" + data.id + "&edit=true";
    this.props.history.push(url);
  };

  handleEarlyEntrance = () => {
    const data = this.state.modelData;
    if (!this.state.earlyReceiptModal) {
      this.setState({ earlyReceiptModal: true });
      return;
    } else if (data.earlyPrice > 0) {
      global.debugPrinter("Do api call", this.state.modelData.earlyPrice, this.state.modelData.id);
    }
    this.setState({ loading: true, modelData: null, actionsModal: false, earlyReceiptModal: false });
    api
      .get("/receipts/earlyReceipt/" + data.id + "/" + data.earlyPrice)
      .then((response) => {
        global.debugPrinter("Early booking", response.data);
        this.initiateState();
        // this.setState({ loading: false });
        // this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };

  handleCompletePayment = () => {
    const data = this.state.modelData;
    global.debugPrinter("handleCompletePayment", data.id);
    this.setState({ loading: true, modelData: null, actionsModal: false, earlyReceiptModal: false });

    api
      .post("/receipts/complete/" + data.id)
      .then((response) => {
        global.debugPrinter("Complete booking", response.data);
        this.initiateState();
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };
  handleCancelBooking = () => {
    const data = this.state.modelData;
    this.setState({ loading: true, actionsModal: false, earlyReceiptModal: false, modelData: null });
    api
      .get("/receipts/cancel/" + data.id)
      .then((response) => {
        global.debugPrinter("Cancel booking", response.data);
        var filtered = this.state.data;
        if (response.data) {
          filtered = filtered.filter((row) => row.id !== data.id);
        }
        this.setState({ loading: false, data: filtered });
        // this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
    // Call Api to cancel booking refetch data or remove it from the array
  };
  onEditEarlyPrice = (event) => {
    const val = event.target.value;
    const val2 = /^\d+$/.test(val) && val?.match(/\d/g)?.join("");
    if (val2) {
      const updateState = { ...this.state.modelData };
      updateState["earlyPrice"] = val2.replace(/^0+/, "");
      this.setState({ modelData: updateState });
    }
  };

  render() {
    const data = this.state.data;
    return (
      <div>
        <RctCard>
          {this.state.loading && <RctSectionLoader />}
          <RctCardContent noPadding>
            <ThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable
                theme={this.theme}
                className="table table-striped "
                title={"Available Receipts"}
                data={data}
                columns={this.columns}
                options={this.options}
              />
            </ThemeProvider>
          </RctCardContent>
        </RctCard>

        <Modal isOpen={this.state.actionsModal} toggle={() => this.actionsModal()} centered>
          <ModalHeader toggle={() => this.actionsModal()}>Actions Center</ModalHeader>
          <ModalBody>
            <h1 className="text-center">{this.state?.modelData?.name}</h1>
            <h2 className="text-center">{this.state?.modelData?.chalet}</h2>
            {/* if not already completed [cash,knet + early entrance or not]*/}
            {this.state?.modelData?.control.completable && (
              <div
                className="btn btn-secondary col text-center align-text-center mt-2"
                onClick={this.handleCompletePayment}
              >
                Complete Payment
              </div>
            )}
            {/* if not already have an early entrance receipt AND payment is completed*/}
            {this.state?.modelData?.control.earlyEntrance && (
              <div
                className="btn btn-secondary col text-center align-text-center mt-2"
                onClick={this.handleEarlyEntrance}
              >
                Early Entrance
              </div>
            )}
            {this.state?.modelData?.control.editable && (
              <div
                className="btn btn-secondary col text-center align-text-center mt-2"
                onClick={this.handleEditBooking}
              >
                Edit Receipt
              </div>
            )}
            {this.state?.modelData?.control.cancelable && (
              <div
                className="btn btn-secondary col text-center align-text-center mt-2"
                onClick={this.handleCancelBooking}
              >
                Cancel Receipt
              </div>
            )}
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.earlyReceiptModal} toggle={() => null} centered>
          <ModalHeader toggle={() => this.setState({ earlyReceiptModal: false })}>Early Receipt</ModalHeader>
          <ModalBody>
            <h1 className="text-center">{this.state?.modelData?.name}</h1>
            <h2 className="text-center">{this.state?.modelData?.chalet}</h2>
            <FormControl fullWidth>
              <InputLabel htmlFor="amount">Early Entrance Price</InputLabel>
              <Input
                type="number"
                id="adornment-amount"
                value={this.state?.modelData?.earlyPrice}
                onChange={this.onEditEarlyPrice}
                startAdornment={<InputAdornment position="start">KD</InputAdornment>}
              />
            </FormControl>
            <button
              className="btn btn-secondary col text-center align-text-center mt-2"
              disabled={this.state?.modelData?.earlyPrice <= 0}
              onClick={this.handleEarlyEntrance}
              type="button"
            >
              Submit
            </button>
          </ModalBody>
        </Modal>
        <SweetAlert
          success
          show={this.state.showSuccessAlert}
          title={"Success"}
          showConfirm={false}
          onConfirm={() => {
            //   this.closeSweetAlert();
            //   // navigator.clipboard.writeText(this.state.showSuccessAlert);
          }}
        >
          <div className="justify-content-center d-flex align-items-center">
            <a href={`${this.state.successAlertWhatsapp}`} className="fs-4 fw-bold">
              open whatsapp
            </a>
            <CopyToClipboard text={this.state.successAlertSurvey} onCopy={this.closeSweetAlert}>
              <span className="ml-1 material-icons">content_copy</span>
            </CopyToClipboard>
          </div>
        </SweetAlert>
        <SweetAlert danger show={this.state.showFailedAlert} title="Failed" onConfirm={this.closeSweetAlert} />
      </div>
    );
  }
}
