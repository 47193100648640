import React, { useState, useEffect } from "react";
import { RctCard, RctCardContent } from "components/RctCard";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ListItem } from "@material-ui/core";
import api from "api";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";
import CustomerDetailsRow from "./CustomerDetailsRow";
import CustomerDetailsTable from "./CustomerDetailsTable";

const CustomerInfo = () => {
  const [customerInfo, setCustomerInfo] = useState({ id: null, name: null, mobile: null });
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customerDetails, setCustomerDetails] = useState(null);

  useEffect(() => {
    api
      .get("/general/customers")
      .then((res) => {
        setCustomers(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!customerInfo.id) return;
    setCustomerDetails(null);
    setLoading(true);

    api
      .get(`/general/customer/${customerInfo.id}`)
      .then((res) => {
        setCustomerDetails(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [customerInfo]);

  const onChangeCustomerName = (event, newValue) => {
    var newInfo = newValue;
    if (typeof newValue === "string") {
      newInfo = {
        name: newValue,
        mobile: customerInfo?.mobile,
      };
    } else if (newValue && newValue?.inputValue) {
      // Create a new value from the user input
      newInfo = {
        name: newValue.inputValue,
        mobile: customerInfo?.mobile,
        id: null,
      };
    }
    if (newInfo === null) {
      newInfo = {
        name: "",
        mobile: "",
      };
    }
    setCustomerInfo(newInfo);
  };

  const onChangeCustomerMobile = (event, newValue) => {
    var newInfo = newValue;
    if (typeof newValue === "string") {
      newInfo = {
        name: customerInfo?.name,
        mobile: newValue,
      };
    } else if (newValue && newValue?.inputValue) {
      // Create a new value from the user input
      newInfo = {
        name: customerInfo?.name,
        mobile: newValue.inputValue,
        id: null,
      };
    }
    if (newInfo === null) {
      newInfo = {
        name: "",
        mobile: "",
      };
    }
    setCustomerInfo(newInfo);
  };

  const OnCustomerNameGetOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.name;
  };

  const OnCustomerMobileGetOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.mobile;
  };

  return (
    <RctCard>
      {loading && <RctSectionLoader />}
      <RctCardContent>
        <FormControl fullWidth className="mt-sm-0 mt-xs-0 mt-lg-0 mt-2">
          <Autocomplete
            value={customerInfo?.name}
            onChange={onChangeCustomerName}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={customers}
            getOptionLabel={OnCustomerNameGetOptionLabel}
            renderOption={(props, option) => <ListItem component="div">{props?.name}</ListItem>}
            sx={{ width: 300 }}
            freeSolo
            onError={() => {}}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Customer Name"
                error={!customerInfo?.id && (customerInfo?.mobile !== null || customerInfo?.name !== null)}
                helperText={!customerInfo?.id && customerInfo?.mobile !== null && "Customer name not found"}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth aria-describedby="name-helper-text" className="mt-4">
          <Autocomplete
            value={customerInfo?.mobile}
            onChange={onChangeCustomerMobile}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={customers}
            getOptionLabel={OnCustomerMobileGetOptionLabel}
            renderOption={(props, option) => <ListItem component="div">{props?.mobile}</ListItem>}
            sx={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label="Customer Mobile Number"
                error={!customerInfo?.id && (customerInfo?.mobile !== null || customerInfo?.name !== null)}
                helperText={!customerInfo?.id && customerInfo?.mobile !== null && "Customer mobile not found"}
              />
            )}
          />
        </FormControl>

        {customerDetails && <CustomerDetailsRow customerDetails={customerDetails} />}
        {customerDetails && <CustomerDetailsTable data={customerDetails.receipts} />}
      </RctCardContent>
    </RctCard>
  );
};

export default CustomerInfo;
