/**
 * Horizontal App
 */
import React, { Component } from "react";
import { Route, withRouter, Redirect, Switch } from "react-router-dom";

// router service
import routerService from "../services/_routerReceipts";

class ReceiptsLayout extends Component {
  render() {
    const { match, location } = this.props;
    if (location.pathname === "/receipts") {
      return <Redirect to={"/receipts/view"} />;
    }
    return (
      <Switch>
        {routerService &&
          routerService.map((route, key) => (
            <Route key={key} path={`${match.url}/${route.path}`} component={route.component} />
          ))}
        <Redirect to="/404" />
      </Switch>
    );
  }
}

export default withRouter(ReceiptsLayout);
