/**
 * Footer
 */
import React from "react";

// app config
import AppConfig from "constants/AppConfig";

const Footer = () => (
  <div className="rct-footer d-flex justify-content-end">
    <h5 className="mb-0">{AppConfig.copyRightText}</h5>
  </div>
);

export default Footer;
