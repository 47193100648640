import {
  TOKEN,
  USERNAME,
  EMAIL,
  DISPLAY_NAME,
  DISPLAY_PICTURE,
  USER_ID,
  SUPER_ADMIN,
} from "constants/LocalStorageConfig";
import instance from "./instance";

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    global.debugPrinter("Response2", response.data, response.statusCode, response.status);
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem(TOKEN);
      localStorage.removeItem(USERNAME);
      localStorage.removeItem(EMAIL);
      localStorage.removeItem(DISPLAY_NAME);
      localStorage.removeItem(DISPLAY_PICTURE);
      localStorage.removeItem(USER_ID);
      localStorage.removeItem(SUPER_ADMIN);
      window.location.reload();
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
