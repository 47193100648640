import React from "react";
import SwipeableViews from "react-swipeable-views";
import TableView from "./TableView";
const ViewTabs = ({ index, data, pageNum, onUpdate, onDelete }) => {
  return (
    <SwipeableViews index={index}>
      <TableView data={data[pageNum].list} onUpdate={onUpdate} onDelete={onDelete} />
      {data[pageNum].properties.map((property) => {
        return (
          <TableView
            data={data[pageNum].list.filter((e) => {
              return e.properties.includes(property);
            })}
            key={property}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        );
      })}
    </SwipeableViews>
  );
};

export default ViewTabs;
