import React from "react";

import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
const ChaletTabs = ({ index, data, onChaletChange, openAddModal }) => {
  return (
    <div style={{ display: "flex" }} className="row justify-content-between p-3">
      <SwipeableViews>
        <Tabs value={index} indicatorColor="primary" textColor="primary" onChange={onChaletChange}>
          {data.map((chalet) => {
            return <Tab label={chalet.title} key={"Tab:" + chalet.title} />;
          })}
        </Tabs>
      </SwipeableViews>
      <h1 onClick={openAddModal}>create</h1>
    </div>
  );
};

export default ChaletTabs;
