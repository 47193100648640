/**
 * App Settings Reducers
 */

import { COLLAPSED_SIDEBAR } from "redux/actions/types";

// app config
import AppConfig from "constants/AppConfig";

/**
 * initial app settings
 */
const INIT_STATE = {
  navCollapsed: AppConfig.navCollapsed,
  miniSidebar: AppConfig.miniSidebar,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // collapse sidebar
    case COLLAPSED_SIDEBAR:
      return { ...state, navCollapsed: action.isCollapsed };

    default:
      return { ...state };
  }
};

export default reducer;
