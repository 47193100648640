import React, { useEffect, useState } from "react";
import { RctCard, RctCardContent } from "components/RctCard";
import FeedbackList from "./FeedbackList";
import FeedbackRadarChart from "./FeedbackRadarChart";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";
import api from "api";
const SurveyReport = () => {
  const [comments, setComments] = useState([]);
  const [radarChart, setRadarChart] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get("/survey/reports")
      .then((res) => {
        const data = res.data;
        setComments(data.comments);
        setRadarChart(data.radarChart);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <RctCard>
      {loading && <RctSectionLoader />}

      <RctCardContent>
        <FeedbackRadarChart data={radarChart} />
        <FeedbackList data={comments} />
      </RctCardContent>
    </RctCard>
  );
};

export default SurveyReport;
