/**
 * App Routes
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "react-sidebar";
import { Scrollbars } from "react-custom-scrollbars-2";
import classnames from "classnames";

// Components
import Header from "components/Header/Header";
import SidebarContent from "components/Sidebar";
import Footer from "components/Footer/Footer";

// preload Components
import PreloadHeader from "components/PreloadLayout/PreloadHeader";
import PreloadSidebar from "components/PreloadLayout/PreloadSidebar";

// actions
import { collapsedSidebarAction } from "redux/actions";

class MainApp extends Component {
  state = {
    loadingHeader: true,
    loadingSidebar: true,
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    setTimeout(() => {
      this.setState({ loadingHeader: false, loadingSidebar: false });
    }, 500);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { windowWidth } = this.state;
    if (nextProps.location !== this.props.location) {
      if (windowWidth <= 1199) {
        this.props.collapsedSidebarAction(false);
      }
    }
  }

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  renderPage() {
    const { children } = this.props;
    return (
      <Scrollbars className="rct-scroll" autoHide autoHideDuration={100} style={this.getScrollBarStyle()}>
        <div className="rct-page-content">
          {children}
          <Footer />
        </div>
      </Scrollbars>
    );
  }

  // render header
  renderHeader() {
    const { loadingHeader } = this.state;
    if (loadingHeader) {
      return <PreloadHeader />;
    }
    return <Header />;
  }

  //render Sidebar
  renderSidebar() {
    const { loadingSidebar } = this.state;
    if (loadingSidebar) {
      return <PreloadSidebar />;
    }
    return <SidebarContent />;
  }

  //Scrollbar height
  getScrollBarStyle() {
    return {
      height: "calc(100vh - 50px)",
    };
  }

  render() {
    const { navCollapsed, miniSidebar } = this.props.settings;
    const { windowWidth } = this.state;
    return (
      <div className="app">
        <div className="app-main-container crm-layout">
          <Sidebar
            sidebar={this.renderSidebar()}
            open={windowWidth <= 1199 ? navCollapsed : false}
            docked={windowWidth > 1199 ? !navCollapsed : false}
            onSetOpen={() => this.props.collapsedSidebarAction(false)}
            styles={{ content: { overflowY: "" } }}
            contentClassName={classnames({ "app-conrainer-wrapper": miniSidebar })}
          >
            <div className="app-container">
              <div className="rct-app-content">
                <div className="app-header">{this.renderHeader()}</div>
                <div className="rct-page">{this.renderPage()}</div>
              </div>
            </div>
          </Sidebar>
        </div>
      </div>
    );
  }
}

// map state to props
const mapStateToProps = ({ settings }) => {
  return { settings };
};

export default withRouter(
  connect(mapStateToProps, {
    collapsedSidebarAction,
  })(MainApp)
);
