import React from "react";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const PropertyTabs = ({ data, chaletIndex, propertyIndex, onPropertyChange }) => {
  return (
    <SwipeableViews index={chaletIndex}>
      {data.map((chalet) => {
        return (
          <Tabs
            key={chalet.title}
            value={propertyIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={onPropertyChange}
          >
            <Tab label="All" />
            {data[chaletIndex].properties.map((property) => {
              return <Tab label={property} key={`tab-property-${property}`} />;
            })}
          </Tabs>
        );
      })}
    </SwipeableViews>
  );
};
export default PropertyTabs;
