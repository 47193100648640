import React from "react";
import { Label, Input } from "reactstrap";
import FormControl from "@material-ui/core/FormControl";
const Note = ({ onNoteChange, value, onPageChange }) => {
	return (
		<div>
			<div className="row row-eq-height">
				<FormControl fullWidth>
					<Label htmlFor="note">Extra Notes</Label>
					<Input type="textarea" id="adornment-note" value={value} onChange={onNoteChange} style={{ height: 180 }} />
				</FormControl>
			</div>
			<div className="d-flex flex-row-reverse justify-content-between">
				<button
					className="btn btn-primary mt-4"
					onClick={() => {
						onPageChange(null, 4);
					}}
					type="button"
				>
					Next
				</button>

				<button
					className="btn btn-primary mt-4"
					onClick={() => {
						onPageChange(null, 2);
					}}
					type="button"
				>
					previous
				</button>
			</div>
		</div>
	);
};

export default Note;
