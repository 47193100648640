import React, { Component } from "react";
import FormControl from "@material-ui/core/FormControl";
import DatePicker from "components/DatePicker";
import { Label } from "reactstrap";
export default class ChaletDetails extends Component {
  render() {
    const { availableChalets, fromDate, toDate, fromDateExtraLabel, toDateExtraLabel, nextLabel = "Next" } = this.props;
    const {
      onPageChange,
      onNext = onPageChange,
      onSelectChalet,
      onSelectChaletProperty,
      onFromDateChange,
      onToDateChange,
    } = this.props;
    const isDisabled = () => {
      const noPropertySelected =
        availableChalets.length === 0 ||
        availableChalets
          .filter((chalet) => chalet.selected)[0]
          .available.filter((property) => {
            return property.selected;
          }).length === 0;

      const isFromDateSet = fromDate === undefined || fromDate === null;
      const isToDateSet = toDate === undefined || toDate === null;

      return noPropertySelected || isFromDateSet || isToDateSet;
    };

    return (
      <div>
        <div className="row row-eq-height">
          <div className="col-lg-6 col-sm-12 col-md-6 w-xs-half-block">
            <FormControl disabled fullWidth>
              <Label htmlFor="chaletChoice" className="MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
                Chalet Choice
              </Label>
              <div className="btn-group mt-0" role="group" id="chaletChoice">
                {availableChalets.map((chalet, index) => {
                  return (
                    <button
                      key={`id-${index}`}
                      type="button"
                      className={`btn col ${chalet.selected && "btn-primary"}`}
                      onClick={() => {
                        onSelectChalet(index);
                      }}
                    >
                      {chalet.name}
                    </button>
                  );
                })}
              </div>
            </FormControl>
            <FormControl disabled fullWidth className="mt-4">
              <Label
                htmlFor="chaletPropertyChoice"
                className="MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
              >
                Chalet Property
              </Label>
              <div className="btn-group" role="group" id="chaletPropertyChoice">
                {availableChalets
                  .filter((chalet) => chalet.selected)
                  .map((chalet) => {
                    return chalet.available.map((property, index) => {
                      return (
                        <button
                          key={`id-sub-${index}`}
                          type="button"
                          className={`btn col ${property.selected && "btn-primary"}`}
                          onClick={() => {
                            onSelectChaletProperty(index);
                          }}
                        >
                          {property.name}
                        </button>
                      );
                    });
                  })}
              </div>
            </FormControl>
          </div>
          <div className="col-lg-6 col-sm-12 col-md-6 w-xs-half-block">
            <DatePicker
              id="chaletChoice1"
              label="From Date"
              onDateChange={onFromDateChange}
              value={fromDate}
              extra={fromDateExtraLabel}
            />
            <p />
            <DatePicker
              id="chaletChoice2"
              label="To Date"
              onDateChange={onToDateChange}
              value={toDate}
              extra={toDateExtraLabel}
            />
          </div>
        </div>
        <div className="d-flex flex-row-reverse justify-content-between">
          <button
            className="btn btn-primary mt-4"
            disabled={isDisabled()}
            onClick={() => {
              onNext(null, 2);
            }}
            type="button"
          >
            {nextLabel}
          </button>

          <button
            className="btn btn-primary mt-4"
            onClick={() => {
              onPageChange(null, 0);
            }}
            type="button"
          >
            previous
          </button>
        </div>
      </div>
    );
  }
}
