import React, { useState, useEffect } from "react";
import { RctCard, RctCardContent } from "components/RctCard";

const SurveyGenerate = () => {
  return (
    <RctCard>
      <RctCardContent>TODO</RctCardContent>
    </RctCard>
  );
};

export default SurveyGenerate;
