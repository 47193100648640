/**
 * Sidebar Content
 */
import React, { Component } from "react";
import List from "@material-ui/core/List";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import NavMenuItem from "./NavMenuItem";

import { onToggleMenu } from "redux/actions";

class SidebarContent extends Component {
  toggleMenu(menu, stateCategory) {
    let data = {
      menu,
      stateCategory,
    };
    this.props.onToggleMenu(data);
  }

  render() {
    const { sidebarMenus } = this.props.sidebar;
    return (
      <div className="rct-sidebar-nav">
        <nav className="navigation">
          <List className="rct-mainMenu p-0 m-0 list-unstyled">
            {sidebarMenus.category1.map((menu, key) => (
              <NavMenuItem menu={menu} key={key} onToggleMenu={() => this.toggleMenu(menu, "category1")} />
            ))}
          </List>
        </nav>
      </div>
    );
  }
}

// map state to props
const mapStateToProps = ({ sidebar }) => {
  return { sidebar };
};

export default withRouter(
  connect(mapStateToProps, {
    onToggleMenu,
  })(SidebarContent)
);
