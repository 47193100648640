/**
 * Preload Header
 */
import React from "react";
import ContentLoader from "react-content-loader";

const PreloadHeader = () => (
  <div className="px-md-4 px-3 preload-header">
    <div className="d-md-block d-none"></div>
    <div className="d-md-none d-block py-2 py-md-0">
      <ContentLoader
        speed={1}
        width={750}
        height={50}
        backgroundColor={"rgba(0,0,0,0.05)"}
        foregroundColor={"rgba(0,0,0,0.04)"}
      >
        <circle cx="23" cy="27" r="23" />
        <circle cx="80" cy="27" r="23" />

        <rect x="415" y="6" rx="0" ry="0" width="40" height="40" />
        <rect x="465" y="6" rx="0" ry="0" width="80" height="40" />
        <rect x="555" y="6" rx="0" ry="0" width="60" height="40" />
        <circle cx="640" cy="25" r="21" />
        <circle cx="685" cy="25" r="21" />
        <rect x="710" y="6" rx="0" ry="0" width="40" height="40" />
      </ContentLoader>
    </div>
  </div>
);

export default PreloadHeader;
