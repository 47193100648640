import React from "react";
import { format } from "date-fns";

const PreviewReceipt = ({ allInfo, onPageChange, getChalet, getProperties, submitRequest }) => {
  const Row = ({ title, value }) => {
    return (
      <div className="row justify-content-baseline">
        <h3 className="col col-lg-3">{title}</h3>
        <p className="col">{value}</p>
      </div>
    );
  };
  const ColoredLine = ({ color = "lightgrey" }) => (
    <div
      className="col col-lg-5"
      style={{
        color: color,
        backgroundColor: color,
        height: 1,
        marginTop: 20,
        width: "80%",
        marginBottom: 20,
        alignSelf: "center",
      }}
    />
  );

  return (
    <div>
      <div className="row row-eq-height">
        <div className="col-lg-6 col-sm-12 col-md-6 w-xs-half-block">
          <Row title="Receipt ID: " value={allInfo.id} />
          <Row title="Cashier: " value={allInfo.cashierName} />
          <Row title="Customer Name: " value={allInfo.customerInfo.name} />
          <Row title="Customer Mobile: " value={allInfo.customerInfo.mobile} />
          {allInfo.note?.length !== 0 && (
            <div>
              <ColoredLine />
              <Row title="Note: " />
              <p className="col-lg-6">{allInfo.note}</p>
            </div>
          )}
          <ColoredLine />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6 w-xs-half-block">
          <Row title="Payment Type: " value={allInfo.paymentType} />
          <Row title="Full Price: " value={allInfo.fullPrice} />
          <Row title="Insurance Price: " value={allInfo.insurancePrice} />
          <Row title="Deposit Price: " value={allInfo.depositPrice} />
          {allInfo.discountReason && <Row title="Discount Details: " value={allInfo.discountReason} />}
          <ColoredLine />

          <Row title="Chalet: " value={getChalet()} />
          <Row title="Property: " value={getProperties()} />
          <Row title="From Date: " value={format(allInfo.fromDate, "dd/MM/yyyy")} />
          <Row title="To Date: " value={format(allInfo.toDate, "dd/MM/yyyy")} />
          <ColoredLine />
        </div>
      </div>

      <div className="d-flex flex-row-reverse justify-content-between">
        <button
          className="btn btn-primary mt-4"
          onClick={() => {
            submitRequest();
          }}
          type="button"
        >
          Submit
        </button>

        <button
          className="btn btn-primary mt-4"
          onClick={() => {
            onPageChange(null, 3);
          }}
          type="button"
        >
          previous
        </button>
      </div>
    </div>
  );
};
export default PreviewReceipt;
