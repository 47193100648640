import React, { useState } from "react";
import { RctCard, RctCardContent } from "components/RctCard";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import parse from "html-react-parser";
import moment from "moment";
import LoggerModal from "./LoggerModal";

export const LoggerTable = ({ data }) => {
  const [reqres, setReqRes] = useState(null);
  const columns = [
    {
      name: "id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "0px" } }),
        customBodyRender: (data, type, row) => {
          return <p style={{ width: 0, height: 0 }}>{Number(data)}</p>;
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = parseInt(obj1.data, 10);
            let val2 = parseInt(obj2.data, 10);
            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "byUser",
      label: "userName",
      options: {
        setCellProps: () => ({ style: { width: "150px" } }),
        customBodyRender: (data, type, row) => {
          return (
            <div>
              <span>{parse(data.replaceAll("\n", "<br/>"))}</span>
            </div>
          );
        },
      },
    },
    {
      name: "api",
      label: "api",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div>
              <span>{parse(data.replaceAll("\n", "<br/>"))}</span>
            </div>
          );
        },
      },
    },
    {
      name: "status_code",
      label: "Status Code",
      options: {
        setCellProps: () => ({ style: { width: "150px" } }),
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ maxWidth: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "timestamp",
      label: "DateTime",
      options: {
        setCellProps: () => ({ style: { width: "20px" } }),
        customBodyRender: (data, type, row) => {
          var date = moment.unix(data).format("DD/MM/YYYY hh:mm:ssA");
          return (
            <div style={{ maxWidth: 150 }}>
              <span>{date}</span>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    fixedHeader: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    responsive: "standard",
    elevation: 0,
    download: false,
    print: false,
    viewColumns: false,
    sortOrder: {
      name: "id",
      direction: "desc",
    },
    onRowClick: (row) => {
      console.log(row[0].props.children);
      const id = row[0].props.children;
      const theRow = data.filter((e) => e.id === id)[0];
      console.log(theRow);
      setReqRes(theRow);
      //   if (theRow.completed === 1) {
      //     history.push(`/survey/${id}`);
      //   }
    },
  };
  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableCell: {
          head: {
            zIndex: "0 !important",
          },
        },
      },
    });

  return (
    <>
      <LoggerModal
        showModal={reqres !== null}
        res={reqres?.response}
        req={reqres?.request}
        dismiss={() => {
          setReqRes(null);
        }}
      />
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          className="table table-striped "
          title={"Available Comments"}
          data={data}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    </>
  );
};
