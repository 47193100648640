import React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const CustomerDetailsTable = ({ data: tableData }) => {
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "chalet",
      label: "Chalet",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "period",
      label: "Period",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 200 }}>
              <span>{data}</span>
            </div>
          );
        },
        // sortCompare: (order) => {
        //   return (obj1, obj2) => {
        //     console.log(obj1);
        //     var date1 = obj1.data;
        //     date1 = date1.substring(0, date1.indexOf(" "));
        //     var date2 = obj2.data;
        //     date2 = date2.substring(0, date2.indexOf(" "));
        //     const dateLeft = parse(date1, "dd/MM/yyyy", new Date());
        //     const dateRight = parse(date2, "dd/MM/yyyy", new Date());
        //     return order === "asc" ? compareAsc(dateLeft, dateRight) : compareDesc(dateLeft, dateRight);
        //   };
        // },
      },
    },
    {
      name: "amounts",
      label: "Amount",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 120 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "cashier",
      label: "Cashier",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },

    {
      name: "note",
      label: "Note",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 200 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    fixedHeader: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    responsive: "standard",
    elevation: 0,
    download: false,
    print: false,
    viewColumns: false,
    sortOrder: {
      name: "period",
      direction: "desc",
    },
  };

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableCell: {
          head: {
            zIndex: "0 !important",
          },
        },
      },
    });
  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        className="table table-striped "
        title={"Receipts"}
        data={tableData.map((td) => {
          td.period = `${td.fromDate} - ${td.toDate}`;
          td.amounts = `${td.price} | ${td.insurance} | ${td.deposit}`;
          return td;
        })}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
};

export default CustomerDetailsTable;
