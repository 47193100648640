/**
 * App.js Layout Start Here
 */
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { NotificationContainer } from "react-notifications";

// rct theme provider
import RctThemeProvider from "./RctThemeProvider";
import RctCRMLayout from "../components/RctCRMLayout";

// CRM layout
import CRMLayout from "./CRMLayout";
import ReceiptsLayout from "./ReceiptsLayout";
import CommentsLayout from "./CommentsLayout";
import CalendarLayout from "./CalendarLayout";
import ExtrasLayout from "./ExtrasLayout";
import SurveyLayout from "./SurveyLayout";
import AuthenticationLayout from "./AuthenticationLayout";
import NotFound from "routes/404";
import {
  TOKEN,
  USERNAME,
  EMAIL,
  DISPLAY_NAME,
  DISPLAY_PICTURE,
  USER_ID,
  SUPER_ADMIN,
} from "constants/LocalStorageConfig";
import api from "api";

class App extends Component {
  componentDidMount() {
    console.log("refresh data");
    api
      .get("/auth/refresh")
      .then((res) => {
        const tokenData = res.data.tokenData;
        if (tokenData) {
          localStorage.setItem(USERNAME, tokenData.username);
          localStorage.setItem(EMAIL, tokenData.email);
          localStorage.setItem(DISPLAY_NAME, tokenData.displayName);
          localStorage.setItem(DISPLAY_PICTURE, tokenData.displayPicture);
          localStorage.setItem(USER_ID, tokenData.id);
          localStorage.setItem(SUPER_ADMIN, tokenData.isSuperAdmin);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  render() {
    const { location } = this.props;

    if (!localStorage.getItem(TOKEN)) {
      if (location.pathname === "/") {
        return <Redirect to={"/authentication"} />;
      }
      return (
        <Switch>
          <Route path="/authentication" component={AuthenticationLayout} />
          <Redirect to="/authentication" />
        </Switch>
      );
    } else {
      if (location.pathname === "/") {
        return <Redirect to={"/dashboard"} />;
      }
      return (
        <RctThemeProvider>
          <NotificationContainer />
          <RctCRMLayout>
            <Switch>
              <Route path="/dashboard" component={CRMLayout} />
              <Route path="/receipts" component={ReceiptsLayout} />
              <Route path="/comments" component={CommentsLayout} />
              <Route path="/extras" component={ExtrasLayout} />
              <Route path="/survey" component={SurveyLayout} />
              <Route path="/calendar" component={CalendarLayout} exact={true} />
              <Route path="/404" component={NotFound} />
              <Redirect from="/" to="/dashboard" />
              <Redirect to="/404" />
            </Switch>
          </RctCRMLayout>
        </RctThemeProvider>
      );
    }
  }
}
export default App;
