/**
 * Horizontal App
 */
import React, { Component } from "react";
// app default layout
import { RctCard, RctCardContent } from "components/RctCard";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import api from "api";
import AppConfig from "constants/AppConfig";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";
import SweetAlert from "react-bootstrap-sweetalert";
import { CopyToClipboard } from "react-copy-to-clipboard";

class CalendarLayout extends Component {
  state = {
    loading: true,
    popupModal: false,
    events: [],
    earlyReceiptModal: false,
    showSuccessAlert: false,
    showFailedAlert: false,
    successAlertWhatsapp: "",
  };
  componentDidMount() {
    this.initiateState();
  }

  initiateState = () => {
    this.setState({ loading: true, popupModal: false, events: [], earlyReceiptModal: false });

    api
      .get("/receipts/calendar")
      .then((response) => {
        // handle success
        global.debugPrinter("calendar data", response.data);
        this.setState(response.data);
        this.setState({ loading: false });
      })
      .catch((error) => {
        // handle error
        global.debugPrinter("error", error);
        this.setState({ loading: false });
      });
  };
  closeSweetAlert = () => {
    this.setState({
      showSuccessAlert: false,
      showFailedAlert: false,
      successAlertWhatsapp: "",
    });
  };

  generateSurvey = (id) => {
    this.setState({ loading: true });
    api
      .get(`/survey/generate/${id}`)
      .then((response) => {
        var newData = this.state.events.map((row2) => {
          const row = row2.extendedProps;
          if (row.id === id) {
            row.control.surveyEnable = false;
          }
          return row2;
        });

        this.setState({
          loading: false,
          showSuccessAlert: true,
          successAlertWhatsapp: response.data.whatsapp,
          successAlertSurvey: response.data.messageUrl,
          events: newData,
        });
      })
      .catch((error) => {
        // handle error
        global.debugPrinter("error", error);
        this.setState({ loading: false, showFailedAlert: true });
      });
  };
  handleDateClick = (args) => {
    if (!args) {
      this.setState({
        popupModal: false,
        modalData: null,
      });
      return;
    }
    // bind with an arrow function
    const details = { ...args.event.extendedProps, earlyPrice: 50 };
    this.setState({
      popupModal: true,
      modalData: details,
    });
  };
  ModalRow = ({ title, data }) => {
    if (data === null || data === false) return null;
    return (
      <div className="row">
        <p className="col">{title}:</p>
        <p className="col">{data}</p>
      </div>
    );
  };

  ModalIcon = ({ data, icon, onClick, iconText }) => {
    if (data === null || data === false || data === 0) return null;
    return (
      <div onClick={onClick}>
        <i className={icon + " ml-2 mr-2"} style={{ fontSize: 34 }}>
          {iconText}
        </i>
      </div>
    );
  };

  handleCancelBooking = () => {
    const data = this.state.modalData;
    this.setState({ loading: true, earlyReceiptModal: false, popupModal: false });
    api
      .get("/receipts/cancel/" + data.id)
      .then((response) => {
        global.debugPrinter("Cancel booking", response.data);
        var filtered = this.state.events;
        if (response.data) {
          filtered = filtered.filter((row) => row.extendedProps.id !== data.id);
        }
        this.setState({ loading: false, events: filtered });
        // this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
    // Call Api to cancel booking refetch data or remove it from the array
  };

  handleCancelTemporaryBooking = () => {
    const data = this.state.modalData;
    this.setState({ loading: true, earlyReceiptModal: false, popupModal: false });
    api
      .get("/receipts/temp/cancel/" + data.id)
      .then((response) => {
        global.debugPrinter("Cancel temporary booking", response.data);
        var filtered = this.state.events;
        if (response.data) {
          filtered = filtered.filter((row) => row.extendedProps.id !== data.id);
        }
        this.setState({ loading: false, events: filtered });
        // this.setState({ loading: false });
        // this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
    // Call Api to cancel booking refetch data or remove it from the array
  };

  handleEditBooking = () => {
    const data = this.state.modalData;
    global.debugPrinter("handleEditBooking", data.id);
    const url = "/receipts/create?id=" + data.id + "&edit=true";
    this.props.history.push(url);
  };
  handleEarlyEntrance = () => {
    const data = this.state.modalData;
    if (!this.state.earlyReceiptModal) {
      this.setState({ earlyReceiptModal: true });
      return;
    } else if (data.earlyPrice > 0) {
      global.printTodo("Do api call", this.state.modalData.earlyPrice, this.state.modalData.id);
    }
    this.setState({ loading: true, modalData: null, popupModal: false, earlyReceiptModal: false });
    api
      .get("/receipts/earlyReceipt/" + data.id + "/" + data.earlyPrice)
      .then((response) => {
        global.debugPrinter("Early booking", response.data);
        this.initiateState();
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };
  handleCompletePayment = () => {
    const data = this.state.modalData;
    global.debugPrinter("handleCompletePayment", data.id);
    this.setState({ loading: true, popupModal: false, modalData: null });
    api
      .post("/receipts/complete/" + data.id)
      .then((response) => {
        global.debugPrinter("Complete booking", response.data);
        this.initiateState();
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };
  onEditEarlyPrice = (event) => {
    const val = event.target.value;
    const val2 = /^\d+$/.test(val) && val?.match(/\d/g)?.join("");
    if (val2) {
      const updateState = { ...this.state.modalData };
      updateState["earlyPrice"] = val2.replace(/^0+/, "");
      this.setState({ modalData: updateState });
    }
  };
  handleTemporaryBooking = (event) => {
    const data = this.state.modalData;
    global.debugPrinter("complete payment", data.id);
    const url = "/receipts/create?id=" + data.id + "&complete=true";
    this.props.history.push(url);
  };

  download = (data) => {
    window.open(AppConfig.apiUrl + data, "_self");
  };
  openPage = (data) => {
    window.open(data, "_self");
  };

  render() {
    const ModalRow = this.ModalRow;
    const ModalIcon = this.ModalIcon;
    return (
      <RctCard>
        <RctCardContent>
          {this.state.loading && <RctSectionLoader />}
          <div className="demo-app-calendar">
            <FullCalendar
              eventContent={(args) => {
                const theBadge = document.createElement("span");
                const status = args.event.extendedProps.status;
                const badge = status ? " badge-success" : " badge-danger";

                if (status !== null) {
                  theBadge.innerHTML = "&nbsp;";
                  theBadge.setAttribute("class", `badge badge-xs ${badge}`);
                }

                const theTitle = document.createElement("span");
                theTitle.setAttribute("class", "fc-event-title fc-sticky align-self-center mr-1 ml-1");
                theTitle.innerHTML = args.event.title;
                return { domNodes: [theBadge, theTitle] };
              }}
              plugins={[bootstrapPlugin, dayGridPlugin]}
              aspectRatio={1}
              contentHeight={900}
              handleWindowResize={true}
              editable={false}
              droppable={false}
              displayEventEnd={true}
              headerToolbar={{
                center: "",
                left: "title",
                right: "prev,next",
              }}
              events={this.state.events}
              eventTextColor="white"
              eventBorderColor="transparent"
              eventClick={this.handleDateClick}
              navLinks={false}
              nextDayThreshold="00:00:00"
              bootstrapFontAwesome={{
                close: "fa-close",
                prev: "fa-chevron-left",
                next: "fa-chevron-right",
              }}
            />
          </div>

          <Modal isOpen={this.state.popupModal} toggle={() => this.handleDateClick()} centered>
            <ModalHeader
              close={
                <button className="close" onClick={() => this.handleDateClick()}>
                  ×
                </button>
              }
            >
              <div>{this.state.modalData?.name}</div>
            </ModalHeader>
            <ModalBody>
              <ModalRow title="From Date" data={this.state?.modalData?.fromDate} />
              <ModalRow title="To Date" data={this.state?.modalData?.toDate} />
              <ModalRow title="Chalet" data={this.state?.modalData?.chalet} />
              <ModalRow title="Price" data={this.state?.modalData?.amounts?.price} />

              <ModalRow title="Insurance" data={this.state?.modalData?.amounts?.insurance} />
              <ModalRow title="Deposit" data={this.state?.modalData?.amounts?.deposit} />
              <ModalRow title="Is Paid" data={this.state?.modalData?.isPaid} />
              <ModalRow title="Payment Type" data={this.state?.modalData?.paymentType} />
              <ModalRow title="Cashier" data={this.state?.modalData?.cashier} />
              {this.state?.modalData?.discountReason && (
                <div>
                  <p>Discount Reason:</p>
                  <p>&emsp;&emsp;{this.state?.modalData?.discountReason}</p>
                </div>
              )}
              {this.state?.modalData?.note && (
                <div>
                  <p>Note:</p>
                  <p>&emsp;&emsp;{this.state?.modalData?.note}</p>
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <div className="container">
                <div className="row d-flex">
                  <div className="col-6">
                    <div className="row">
                      {/* isTemporary */}
                      <ModalIcon
                        data={this.state?.modalData?.control?.isTemporary}
                        icon="zmdi zmdi-edit"
                        onClick={this.handleTemporaryBooking}
                      />
                      {/* CancelTemporary */}
                      <ModalIcon
                        data={this.state?.modalData?.control?.isTemporary}
                        icon="zmdi zmdi-close"
                        onClick={this.handleCancelTemporaryBooking}
                      />
                      {/* Complete payment */}
                      <ModalIcon
                        data={this.state?.modalData?.control?.completable}
                        icon="zmdi zmdi-money"
                        onClick={this.handleCompletePayment}
                      />
                      {/* Early Entrance */}
                      <ModalIcon
                        data={this.state?.modalData?.control?.earlyEntrance}
                        icon="zmdi zmdi-airplane"
                        onClick={this.handleEarlyEntrance}
                      />
                      {/* Cancel */}
                      <ModalIcon
                        data={this.state?.modalData?.control?.cancelable}
                        icon="zmdi zmdi-close"
                        onClick={this.handleCancelBooking}
                      />
                      {/* Edit */}
                      <ModalIcon
                        data={this.state?.modalData?.control?.editable}
                        icon="zmdi zmdi-edit"
                        onClick={this.handleEditBooking}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row  justify-content-end">
                      {/* Reminder Message */}
                      <ModalIcon
                        data={this.state?.modalData?.amounts?.price}
                        icon="zmdi zmdi-notifications-active"
                        onClick={() => this.openPage(this.state?.modalData?.reminderLink)}
                      />
                      <ModalIcon
                        data={this.state?.modalData?.amounts?.price}
                        icon="zmdi zmdi-download"
                        onClick={() => this.download(this.state?.modalData?.downloadLink)}
                      />
                      <ModalIcon
                        data={this.state?.modalData?.control?.surveyEnable}
                        icon="material-icons"
                        iconText="how_to_vote"
                        onClick={() => this.generateSurvey(this.state?.modalData?.id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ModalFooter>
          </Modal>
          <Modal isOpen={this.state.earlyReceiptModal} toggle={() => null} centered>
            <ModalHeader toggle={() => this.setState({ earlyReceiptModal: false })}>Early Receipt</ModalHeader>
            <ModalBody>
              <h1 className="text-center">{this.state?.modalData?.name}</h1>
              <h2 className="text-center">{this.state?.modalData?.chalet}</h2>
              <FormControl fullWidth>
                <InputLabel htmlFor="amount">Early Entrance Price</InputLabel>
                <Input
                  type="number"
                  id="adornment-amount"
                  value={this.state?.modalData?.earlyPrice}
                  onChange={this.onEditEarlyPrice}
                  startAdornment={<InputAdornment position="start">KD</InputAdornment>}
                />
              </FormControl>
              <button
                className="btn btn-secondary col text-center align-text-center mt-2"
                disabled={this.state?.modalData?.earlyPrice <= 0}
                onClick={this.handleEarlyEntrance}
                type="button"
              >
                Submit
              </button>
            </ModalBody>
          </Modal>
        </RctCardContent>
        <SweetAlert
          success
          show={this.state.showSuccessAlert}
          showConfirm={false}
          title={"Success"}
          onConfirm={() => {
            // this.closeSweetAlert();
            // navigator.clipboard.writeText(this.state.showSuccessAlert);
          }}
        >
          <div className="justify-content-center d-flex align-items-center">
            <a href={`${this.state.successAlertWhatsapp}`} className="fs-4 fw-bold">
              open whatsapp
            </a>
            <CopyToClipboard text={this.state.successAlertSurvey} onCopy={this.closeSweetAlert}>
              <span className="ml-1 material-icons">content_copy</span>
            </CopyToClipboard>
          </div>
        </SweetAlert>
        <SweetAlert danger show={this.state.showFailedAlert} title="Failed" onConfirm={this.closeSweetAlert} />
      </RctCard>
    );
  }
}

export default CalendarLayout;
