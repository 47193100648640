/**
 * Reactify Sidebar
 */
import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";

// redux actions
import { collapsedSidebarAction } from "redux/actions";

// components
import UserBlock from "./UserBlock";
import SidebarContent from "./SidebarContent";

class Sidebar extends Component {
  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { windowWidth } = this.state;
    if (nextProps.location !== this.props.location) {
      if (windowWidth <= 1199) {
        this.props.collapsedSidebarAction(false);
      }
    }
  }

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  };

  render() {
    const { enableSidebarBackgroundImage } = this.props;
    return (
      <Fragment>
        <div className={classNames("rct-sidebar", { "background-none": !enableSidebarBackgroundImage })}>
          <div className="rct-sidebar-content sidebar-overlay-dark">
            <div className="p-3">
              <Link to="/" className="logo-mini mt-2">
                <h1>Karam Chalet Admin</h1>
              </Link>
            </div>
            <div className="rct-sidebar-wrap">
              <Scrollbars
                className="rct-scroll"
                autoHide
                autoHideDuration={100}
                style={{ height: "calc(100vh - 60px)" }}
              >
                <UserBlock />
                <SidebarContent />
              </Scrollbars>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

// map state to props
const mapStateToProps = ({ settings }) => {
  const { enableSidebarBackgroundImage, collapsedSidebar, locale } = settings;
  return { enableSidebarBackgroundImage, collapsedSidebar, locale };
};

export default withRouter(
  connect(mapStateToProps, {
    collapsedSidebarAction,
  })(Sidebar)
);
