//Comments Pages
import InsuranceManagement from "routes/Extras/InsuranceManagement";
import ProblemTracker from "routes/Extras/ProblemTracker";
import CustomerInfo from "routes/Extras/CustomerInfo";
import Notification from "routes/Extras/Notification";
import LoggerView from "routes/Extras/LoggerView";

const routes = [
  //Dashboard
  {
    path: "notifications",
    component: Notification,
  },
  {
    path: "insurance",
    component: InsuranceManagement,
  },
  {
    path: "problemTracker",
    component: ProblemTracker,
  },
  {
    path: "customers",
    component: CustomerInfo,
  },
  {
    path: "logger",
    component: LoggerView,
  },
];
export default routes;
