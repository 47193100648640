import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { TabContent } from "reactstrap";
import MUIDataTable from "mui-datatables";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

const TableView = ({ data, onUpdate, onDelete }) => {
  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableCell: {
          head: {
            zIndex: "0 !important",
          },
        },
      },
    });

  const columns = [
    {
      name: "id",
      label: " ",
      options: {
        customBodyRender: (data1, type, row) => {
          let originalRow = data.find((o) => {
            return o.id === data1;
          });
          const btnStyle = {
            MozUserSelect: "none",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            OUserSelect: "none",
            userSelect: "none",
            cursor: "pointer",
          };
          return (
            <div className="row d-flex align-items-center ml-2" style={{ width: 120, minHeight: 50 }}>
              <span
                className={"material-icons text-secondary mr-2"}
                style={btnStyle}
                onClick={() => onDelete(originalRow)}
              >
                close
              </span>
              <span>
                <BootstrapSwitchButton
                  checked={originalRow.isDone}
                  onlabel="Yes"
                  onstyle="success"
                  offlabel="No"
                  offstyle="danger"
                  size="sm"
                  onChange={(checked) => {
                    onUpdate(originalRow, checked);
                  }}
                />
              </span>
            </div>
          );
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: "title",
      label: "title",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150, maxWidth: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "description",
      label: "description",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150, maxWidth: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "properties",
      label: "properties",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150, maxWidth: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "textField",
    selectableRows: "none",
    selectableRowsHeader: false,
    responsive: "standard",
    elevation: 0,
    download: false,
    print: false,
    viewColumns: false,
  };

  const sortedData = data?.sort((a, b) => (b.isDone ? -1 : 1));
  return (
    <TabContent>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable className="table table-striped " data={sortedData} columns={columns} options={options} />
      </ThemeProvider>
    </TabContent>
  );
};

export default TableView;
