/**
 * App Header
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import screenfull from "screenfull";
import Tooltip from "@material-ui/core/Tooltip";
import MenuIcon from "@material-ui/icons/Menu";
import { withRouter } from "react-router-dom";

// actions
import { collapsedSidebarAction } from "redux/actions";

class Header extends Component {
  // function to change the state of collapsed sidebar
  onToggleNavCollapsed = (event) => {
    const val = !this.props.navCollapsed;
    this.props.collapsedSidebarAction(val);
  };

  // toggle screen full
  toggleScreenFull() {
    screenfull.toggle();
  }

  render() {
    return (
      <AppBar position="static" className="rct-header">
        <Toolbar className="d-flex justify-content-between w-100 pl-0">
          <div className="d-flex align-items-center">
            <ul className="list-inline mb-0 navbar-left">
              <li className="list-inline-item" onClick={(e) => this.onToggleNavCollapsed(e)}>
                <Tooltip title="Sidebar Toggle" placement="bottom">
                  <IconButton color="inherit" mini="true" aria-label="Menu" className="humburger p-0">
                    <MenuIcon />
                  </IconButton>
                </Tooltip>
              </li>
            </ul>
          </div>
          <ul className="navbar-right list-inline mb-0 mr-20">
            <li className="list-inline-item setting-icon">
              <Link to="/receipts/create">
                <h3 className="text-primary">Create Receipt</h3>
              </Link>
            </li>
          </ul>
        </Toolbar>
      </AppBar>
    );
  }
}

// map state to props
const mapStateToProps = ({ settings }) => {
  return settings;
};

export default withRouter(
  connect(mapStateToProps, {
    collapsedSidebarAction,
  })(Header)
);
