import React, { useState, useEffect } from "react";
import { RctCard, RctCardContent, RctCardHeading } from "components/RctCard";
import { useParams } from "react-router-dom";

import RctSectionLoader from "../../../components/RctSectionLoader/RctSectionLoader";
import api from "api";

const SurveyView = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { surveyId = "All" } = useParams();

  useEffect(() => {
    setLoading(true);
    api
      .get(`/survey/${surveyId}`)
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [surveyId]);
  const InfoBox = ({ title, value, color }) => {
    return (
      <div className="current-widget bg-secondary mr-1 ml-1 col ">
        <h5>{title}</h5>
        <h3>{value}</h3>
      </div>
    );
  };
  return (
    <RctCard>
      {loading && (
        <div className="p-2">
          <RctSectionLoader />
        </div>
      )}
      <RctCardContent>
        {data?.comment && (
          <div>
            <h2>{data.comment.customer.name}</h2>
            <h2>{data.comment.customer.mobile}</h2>

            <h2 className="mt-4">Details:</h2>
            <h5 className="mt-4">- Chalet: {data.comment.chalet}</h5>
            <h5>- Amounts:</h5>
            <h5 className="ml-4">- Price: {data.comment.amounts.price}</h5>
            <h5 className="ml-4">- Insurance: {data.comment.amounts.insurance}</h5>
            <h5 className="ml-4">- Deposit: {data.comment.amounts.deposit}</h5>
            <h5>- Early Entrance: {data.comment.earlyEntrance === true ? "Yes" : "No"}</h5>
            <h5>- Cashier: {data.comment.cashier}</h5>

            <div className="mt-20 row row-eq justify-content-center ml-1 mr-1">
              {data.matrix.map((matrix) => {
                console.log(matrix);
                return <InfoBox title={matrix.question} value={matrix.answer} />;
              })}
            </div>
            <h2>{data.comment.comment && "Comment"}</h2>
            <h4>{data.comment.comment}</h4>
          </div>
        )}
      </RctCardContent>
    </RctCard>
  );
};

export default SurveyView;
