/**
 * Ecommerce Dashboard
 */
import "redux/actions/ExtraActions";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid } from "recharts";
import AppConfig from "constants/AppConfig";
// page title bar
import RctCollapsibleCard from "components/RctCollapsibleCard/RctCollapsibleCard";
import api from "api";
import { TodayOrdersStatsWidget } from "components/Widgets";
import Chart from "../Components/DashboardChart";
import { Tooltip } from "recharts";
export default class Dashboard extends Component {
  state = {
    usersIncome: [],
    TotalIncome: 0,
    totalPayments: 0,
    incomeByProperties: [],
    incomeByPropertiesPieChart: [],
  };
  componentDidMount() {
    global.printPageName("Dashboard/Details");
    api
      .get("/general/details")
      .then((response) => {
        global.debugPrinter(1, response.data);
        this.setState({ loading: false });
        this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(2, error);
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <div className="ecom-dashboard-wrapper">
        <Helmet>
          <title>Dashboard - Details</title>
          <meta name="description" content="Dashboard Details" />
        </Helmet>
        <div className="row row-eq-height">
          {this.state.usersIncome.map((user) => {
            return (
              <div className="col" key={JSON.stringify(user)}>
                <TodayOrdersStatsWidget
                  title={user.user + "'s Income"}
                  value={user.amount}
                  color="bg-secondary"
                  icon=""
                />
              </div>
            );
          })}
          <div className="col">
            <TodayOrdersStatsWidget
              title={`Total Income - ${this.state.totalPayments}`}
              value={this.state.TotalIncome}
              color="bg-secondary"
              icon=""
            />
          </div>
        </div>
        <div className="row row-eq-height">
          <div className="col-lg-12 col-sm-12 col-md-12 pr-4">
            <RctCollapsibleCard
              heading={"Chalets"}
              fullBlock
              downloadable
              downloadLink={`${AppConfig.apiUrl}download/all`}
            >
              <ResponsiveContainer width="100%" height={330}>
                <BarChart
                  data={this.state.incomeByPropertiesPieChart}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <XAxis dataKey="name" stroke={AppConfig.themeColors.primary} tickLine={false} />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Bar dataKey="value" fill={AppConfig.themeColors.primary} />
                </BarChart>
              </ResponsiveContainer>
            </RctCollapsibleCard>
          </div>
        </div>
        <div className="row row-eq-height">
          {this.state.incomeByProperties.map((chalet) => {
            return (
              <div className="col-lg-6 col-sm-12 col-md-6 w-xs-half-block pr-4" key={JSON.stringify(chalet)}>
                <RctCollapsibleCard
                  heading={"Chalet " + chalet.title}
                  fullBlock
                  downloadable
                  downloadLink={`${AppConfig.apiUrl}${chalet.downloadURL}`}
                >
                  <Chart data={chalet.data} />
                </RctCollapsibleCard>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
