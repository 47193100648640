import React from "react";

const CustomerDetailsRow = ({ customerDetails }) => {
  const InfoBox = ({ title, value, color }) => {
    return (
      <div className="current-widget bg-secondary mr-1 ml-1 col ">
        <h5>{title}</h5>
        <h3>{value}</h3>
      </div>
    );
  };
  return (
    <div className="mt-20 row row-eq justify-content-center ml-1 mr-1">
      <InfoBox title="Visits" value={customerDetails.numOfReceipts} color="bg-secondary" />
      <InfoBox title="First Visit" value={customerDetails.firstVisit} />
      <InfoBox title="Last Visit" value={customerDetails.lastVisit} />
      <InfoBox
        title="Total Value"
        value={customerDetails.totalValueGainedFromFirstVisit}
        icon={null}
        className="col-2"
      />
      {customerDetails.totalValueGainedFromEarlyEntrance !== 0 && (
        <InfoBox title="Early Entrance value" value={customerDetails.totalValueGainedFromEarlyEntrance} />
      )}
      <InfoBox
        title="Cashier"
        value={customerDetails.cashiers.join("\n")}
        color="bg-secondary"
        icon={null}
        className="col-2"
      />

      <InfoBox title="Favorite Chalet" value={customerDetails.mostVisitedChalet} />
      <InfoBox title="Favorite Property" value={customerDetails.mostVisitedProperty} />
    </div>
  );
};

export default CustomerDetailsRow;
