/**
 * App Config File
 */
const AppConfig = {
  brandName: "Karam Chalet", // Brand Name
  navCollapsed: false, // Sidebar collapse
  miniSidebar: false, // Mini Sidebar
  copyRightText: "AziKar24 © 2022 All Rights Reserved.",
  apiUrl: process.env.REACT_APP_BASE_URL,
  // light theme colors
  themeColors: {
    primary: "#677080",
    secondary: "#FFB70F",
  },
};

export default AppConfig;
