import React, { Component } from "react";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ListItem } from "@material-ui/core";

export default class BasicInformationForm extends Component {
  render() {
    const { id, cashierName, customerInfo, customers } = this.props;
    const { onChangeCustomerName, OnCustomerNameFilterOptions, OnCustomerNameGetOptionLabel } = this.props;
    const { onChangeCustomerMobile, OnCustomerMobileFilterOptions, OnCustomerMobileGetOptionLabel } = this.props;
    const { onPageChange } = this.props;
    return (
      <div>
        <div className="row row-eq-height">
          <div className="col-lg-6 col-sm-12 col-md-6 w-xs-half-block">
            <FormControl disabled fullWidth>
              <InputLabel htmlFor="name-disabled">ID</InputLabel>
              <Input id="name-disabled" value={id} />
            </FormControl>
            <FormControl disabled fullWidth aria-describedby="name-helper-text" className="mt-4">
              <InputLabel htmlFor="name-helper">Name</InputLabel>
              <Input id="name-helper" value={cashierName} />
            </FormControl>
          </div>
          <div className="col-lg-6 col-sm-12 col-md-6 w-xs-half-block">
            <FormControl fullWidth className="mt-sm-0 mt-xs-0 mt-lg-0 mt-2">
              <Autocomplete
                value={customerInfo?.name}
                onChange={onChangeCustomerName}
                filterOptions={OnCustomerNameFilterOptions}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={customers}
                getOptionLabel={OnCustomerNameGetOptionLabel}
                renderOption={(props, option) => <ListItem component="div">{props?.name}</ListItem>}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => <TextField {...params} label="Customer Name" />}
              />
            </FormControl>
            <FormControl fullWidth aria-describedby="name-helper-text" className="mt-4">
              <Autocomplete
                value={customerInfo?.mobile}
                onChange={onChangeCustomerMobile}
                filterOptions={OnCustomerMobileFilterOptions}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={customers}
                getOptionLabel={OnCustomerMobileGetOptionLabel}
                renderOption={(props, option) => <ListItem component="div">{props?.mobile}</ListItem>}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => <TextField {...params} label="Customer Mobile Number" />}
              />
            </FormControl>
          </div>
        </div>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn btn-primary mt-4"
            disabled={!(customerInfo?.name?.length !== 0 && customerInfo?.mobile?.length !== 0)}
            onClick={() => {
              onPageChange(null, 1);
            }}
            type="button"
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}
