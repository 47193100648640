import React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const TableTracker = ({ data: tableData }) => {
  const history = useHistory();

  const columns = [
    {
      name: "surveyId",
      label: "ID",
      options: {
        display: "excluded",
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "mobile",
      label: "Mobile",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "chalet",
      label: "Chalet",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "chaletProperties",
      label: "Property",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "period",
      label: "Period",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 200 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "cashier",
      label: "Cashier",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "completed",
      label: "completed",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data === 1 ? "Done" : "Not yet"}</span>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    fixedHeader: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    responsive: "standard",
    elevation: 0,
    download: false,
    print: false,
    viewColumns: false,
    sortOrder: {
      name: "period",
      direction: "desc",
    },
    onRowClick: (row) => {
      const id = row[0].props.children.props.children;
      const theRow = tableData.filter((e) => e.surveyId === id)[0];
      if (theRow.completed === 1) {
        history.push(`/survey/${id}`);
      }
    },
  };

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableCell: {
          head: {
            zIndex: "0 !important",
          },
        },
      },
    });

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        className="table table-striped "
        title={"Survey Tracker"}
        data={tableData}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
};

export default TableTracker;
