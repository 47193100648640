/**
 * Main App
 */
//global actions
import "redux/actions/ExtraActions";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// css
import "styles/reactifyCss";

// app component
import App from "./container/App";

import { configureStore } from "./store";
const MainApp = () => (
  <Provider store={configureStore()}>
    <div className="developmentTag" hidden={process.env.NODE_ENV !== "development"}>
      <div className="developmentText">Development</div>
    </div>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </MuiPickersUtilsProvider>
  </Provider>
);

export default MainApp;
