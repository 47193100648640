const isDebugMode = false;
global.isDebugMode = isDebugMode;

const titlePrinter = false;
global.titlePrinter = titlePrinter;

const todoPrinter = true;
global.todoPrinter = todoPrinter;

const debugPrinter = (...value) => {
  if (isDebugMode) {
    console.log("DEBUG:: ", ...value);
  }
};
global.debugPrinter = debugPrinter;

const printPageName = (value) => {
  // if (titlePrinter) {
  console.log("PAGE: ", value);
  // }
};
global.printPageName = printPageName;

const printTodo = (value) => {
  if (todoPrinter) {
    console.log("TODO: ", value);
  }
};
global.printTodo = printTodo;
