import React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
const FeedbackList = ({ data }) => {
  const history = useHistory();
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: "excluded",
        customBodyRender: (data, type, row) => {
          return (
            <div>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "customer",
      label: "Customer",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150, maxWidth: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "comment",
      label: "Comment",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150, maxWidth: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "chalet",
      label: "Chalet",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150, maxWidth: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    fixedHeader: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    responsive: "standard",
    elevation: 0,
    download: false,
    print: false,
    viewColumns: false,
    onRowClick: (row) => {
      const id = row[0].props.children.props.children;
      history.push(`/survey/${id}`);
      // const theRow = data.filter((e) => e.id === id)[0];
      // console.log(theRow);
    },
  };

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableCell: {
          head: {
            zIndex: "0 !important",
          },
        },
      },
    });

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        className="table table-striped "
        title={"فضفضه"}
        data={data}
        columns={columns}
        options={options}
        disableSelectionOnClick={true}
      />
    </ThemeProvider>
  );
};

export default FeedbackList;
