import React, { Component } from "react";
import { RctCard, RctCardContent } from "components/RctCard";
import api from "api";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";
import MUIDataTable from "mui-datatables";
import { createTheme } from "@material-ui/core/styles";
import parse from "html-react-parser";

export default class Notification extends Component {
  state = {
    data: [],
  };

  componentDidMount() {
    this.setState({ loading: true });
    api
      .get("/general/notification/list")
      .then((response) => {
        global.debugPrinter(response.data);
        this.setState({ loading: false });
        this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  }

  columns = [
    {
      name: "id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "0px" } }),
        customBodyRender: (data, type, row) => {
          return <p style={{ width: 0, height: 0 }}>{Number(data)}</p>;
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = parseInt(obj1.data, 10);
            let val2 = parseInt(obj2.data, 10);
            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "note",
      label: "note",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div>
              <span>{parse(data.replaceAll("\n", "<br/>"))}</span>
            </div>
          );
        },
      },
    },
    {
      name: "addedBy",
      label: "added By",
      options: {
        setCellProps: () => ({ style: { width: "150px" } }),
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ maxWidth: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
  ];

  options = {
    filterType: "dropdown",
    fixedHeader: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    responsive: "standard",
    elevation: 0,
    download: false,
    print: false,
    viewColumns: false,
    sortOrder: {
      name: "id",
      direction: "desc",
    },
  };
  getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableCell: {
          head: {
            zIndex: "0 !important",
          },
        },
      },
    });

  TabContent = ({ children }) => {
    return <div className="mt-2 p-20">{children}</div>;
  };

  handlePriceChange = (e) => {
    const price = e.target.value;
    this.setState({ price });
  };

  handleNoteChange = (e) => {
    const note = e.target.value;
    this.setState({ note });
  };

  isDisabled = () => {
    return this.state.note.length === 0 || this.state.price <= 0;
  };

  closeSweetAlert = () => {
    if (this.state.showSuccessAlert) {
      this.setState({ showSuccessAlert: false, price: 0, note: "" });
    } else {
      this.setState({ showFailedAlert: false });
    }
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    const payload = {
      cashier: 2,
      price: this.state.price,
      note: this.state.note,
    };
    api
      .post("/receipts/insurance/create", payload)
      .then((response) => {
        global.debugPrinter(response.data);
        if (response.data > 0) {
          this.setState({ loading: false, showSuccessAlert: true });
          this.loadInsurances();
        } else {
          this.setState({ loading: false, showFailedAlert: true });
        }
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };

  cancelInsurance = (data) => {
    this.setState({ loading: true });
    api
      .get("/receipts/insurance/cancel/" + data.id)
      .then((response) => {
        global.debugPrinter("Cancel insurance", response.data);
        var filtered = this.state.data;
        if (response.data) {
          filtered = filtered.filter((row) => row.id !== data.id);
        }
        this.setState({ loading: false, data: filtered });
        this.loadInsurances();
        // this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };

  handleTabClick = (event, value) => {
    this.setState({ pageNum: value });
  };

  render() {
    const data = this.state.data;

    return (
      <div>
        <RctCard>
          {this.state.loading && <RctSectionLoader />}
          <RctCardContent>
            <MUIDataTable
              theme={this.theme}
              className="table table-striped "
              data={data}
              columns={this.columns}
              options={this.options}
              title="Notifications"
            />
          </RctCardContent>
        </RctCard>
      </div>
    );
  }
}
