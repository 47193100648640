/**
 * Receipts Routes
 */
import React from "react";
import { Redirect, Switch } from "react-router-dom";

const Survey = ({ match }) => {
  return (
    <div className="dashboard-wrapper">
      <Switch>
        <Redirect to="/404" />
      </Switch>
    </div>
  );
};

export default Survey;
