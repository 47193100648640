/**
 * App Widgets
 */
import React from "react";
import Loadable from "react-loadable";
import PreloadWidget from "components/PreloadLayout/PreloadWidget";

const MyLoadingComponent = () => <PreloadWidget />;

const TodayOrdersStatsWidget = Loadable({
  loader: () => import("./TodayOrdersStats"),
  loading: () => <></>,
});

const LineChart = Loadable({
  loader: () => import("./LineChart"),
  loading: MyLoadingComponent,
});

export { TodayOrdersStatsWidget, LineChart };
