/**
 * Rct Theme Provider
 */
import React, { Component, Fragment } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";

// themes
import { createTheme } from "@material-ui/core/styles";
import AppConfig from "constants/AppConfig";

class RctThemeProvider extends Component {
  render() {
    const { children } = this.props;

    const theme = createTheme({
      palette: {
        primary: {
          main: AppConfig.themeColors.primary,
        },
        secondary: {
          main: AppConfig.themeColors.secondary,
        },
      },
    });

    return (
      <MuiThemeProvider theme={theme}>
        <Fragment>{children}</Fragment>
      </MuiThemeProvider>
    );
  }
}

export default RctThemeProvider;
