//Receipts Pages
import Receipts from "routes/Receipts";
import CreateReceipt from "routes/Receipts/Create";
import CreateTemporaryReceipt from "routes/Receipts/CreateTemporary";
import ViewReceipts from "routes/Receipts/View";
import ViewTemporaryReceipts from "routes/Receipts/ViewTemporary";
import CancelledReceipts from "routes/Receipts/CancelledReceipts";
const routes = [
  {
    path: "receipts",
    component: Receipts,
  },
  {
    path: "create/temporary",
    component: CreateTemporaryReceipt,
  },
  {
    path: "create",
    component: CreateReceipt,
  },
  {
    path: "view/temporary",
    component: ViewTemporaryReceipts,
  },
  {
    path: "view/cancelled",
    component: CancelledReceipts,
  },
  {
    path: "view",
    component: ViewReceipts,
  },
];

export default routes;
