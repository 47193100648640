/**
 * Login Page
 */
import "redux/actions/ExtraActions";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Form, FormGroup, Input } from "reactstrap";
import { RctCard, RctCardContent } from "components/RctCard";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";
import api from "api";
import {
  TOKEN,
  USERNAME,
  EMAIL,
  DISPLAY_NAME,
  DISPLAY_PICTURE,
  USER_ID,
  SUPER_ADMIN,
} from "constants/LocalStorageConfig";

class Signin extends Component {
  state = {
    email: "",
    emailError: null,
    password: "",
    passwordError: null,
    authError: null,
    loading: false,
  };

  /**
   * On User Login
   */
  onUserLogin() {
    var flag = true;
    var emailError = null;
    var passwordError = null;
    if (this.state.email === "") {
      flag = false;
      emailError = "Please enter an email or username";
    }
    if (this.state.password === "") {
      flag = false;
      passwordError = "Please enter a password";
    }

    if (flag) {
      this.doLogin();
    }
    this.setState({ emailError, passwordError });
  }

  doLogin = () => {
    this.setState({ loading: true });
    const payload = {
      username: this.state.email,
      password: this.state.password,
    };
    api
      .post("/auth/login", payload)
      .then((response) => {
        global.debugPrinter(response.data);
        this.setState({ loading: false });
        var authError = null;
        if (!response.data || response.data.token === null) {
          authError = "Invalid Login Information";
          this.setState({ authError });
        } else {
          global.debugPrinter(response.data);
          localStorage.setItem(TOKEN, "Bearer " + response.data.token);
          localStorage.setItem(USERNAME, response.data.username);
          localStorage.setItem(EMAIL, response.data.email);
          localStorage.setItem(DISPLAY_NAME, response.data.displayName);
          localStorage.setItem(DISPLAY_PICTURE, response.data.displayPicture);
          localStorage.setItem(USER_ID, response.data.id);
          localStorage.setItem(SUPER_ADMIN, response.data.isSuperAdmin);
          window.location.reload();
        }
        // this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  Spacer = () => {
    return <div style={{ height: 200 }} />;
  };
  render() {
    const Spacer = this.Spacer;
    const { email, password } = this.state;
    const { loading } = this.state;
    return (
      <div className="main-wrapper bg-primary" style={{ height: "100%" }}>
        <Spacer />
        <div className="d-flex justify-content-center">
          <div className="col-xs-12 col-sm-6 col-md-4 col-xl-4 w-xs-half-block">
            <RctCard center>
              {loading && <RctSectionLoader />}
              <RctCardContent>
                <h1 className="text-center mb-10">Karam Chalet Admin</h1>
                <Form>
                  <FormGroup className="has-wrapper">
                    <Input
                      type="mail"
                      value={email}
                      name="user-mail"
                      id="user-mail"
                      className="has-input input-lg"
                      placeholder="Username / Email"
                      onChange={(event) => {
                        this.setState({ email: event.target.value, emailError: null });
                      }}
                    />
                    <span className="has-icon">
                      <i className="zmdi zmdi-account"></i>
                    </span>
                  </FormGroup>
                  <p className="text-danger">{this.state.emailError}</p>
                  <FormGroup className="has-wrapper">
                    <Input
                      value={password}
                      type="Password"
                      name="user-pwd"
                      id="pwd"
                      className="has-input input-lg"
                      placeholder="Password"
                      onChange={(event) => this.setState({ password: event.target.value, passwordError: null })}
                    />
                    <span className="has-icon">
                      <i className="zmdi zmdi-key"></i>
                    </span>
                  </FormGroup>
                  <p className="text-danger">{this.state.passwordError}</p>
                  <FormGroup className="mb-15">
                    <p className="text-danger text-center">{this.state.authError}</p>

                    <Button
                      color="primary"
                      className="btn-block text-white w-100"
                      variant="contained"
                      size="large"
                      onClick={() => this.onUserLogin()}
                    >
                      Sign In
                    </Button>
                  </FormGroup>
                </Form>
              </RctCardContent>
            </RctCard>
          </div>
        </div>
      </div>
    );
  }
}

export default Signin;
