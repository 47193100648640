/**
 * Site Visitors Chart
 */
import React, { Component } from "react";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

// chart config
import AppConfig from "constants/AppConfig";
class DashboardChart extends Component {
  render() {
    return (
      <ResponsiveContainer width="100%" height={330}>
        <LineChart data={this.props.data}>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="1 2" />
          <Tooltip />
          <Line type="monotone" dataKey="value" stroke={AppConfig.themeColors.primary} />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

export default DashboardChart;
