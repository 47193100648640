import "redux/actions/ExtraActions";
import React, { Component } from "react";
import { RctCard, RctCardContent } from "components/RctCard";
import FormControl from "@material-ui/core/FormControl";
import { Label, Input } from "reactstrap";
import api from "api";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";
import SweetAlert from "react-bootstrap-sweetalert";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import parse from "html-react-parser";

export default class InsuranceManagement extends Component {
  state = {
    pageNum: 0,
    price: 0,
    note: "",
    showSuccessAlert: false,
    showFailedAlert: false,
    data: [],
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.loadInsurances();
  }

  loadInsurances = () => {
    api
      .get("/receipts/insurance/list")
      .then((response) => {
        global.debugPrinter(response.data);
        this.setState({ loading: false });
        this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };
  columns = [
    {
      name: "id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "90px" } }),
        customBodyRender: (data, type, row) => {
          let originalRow = this.state.data.find((o) => {
            return o.id === data;
          });
          const btnStyle = {
            MozUserSelect: "none",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            OUserSelect: "none",
            userSelect: "none",
            cursor: "pointer",
          };
          const btnClasses = "material-icons text-secondary ml-2";
          return (
            <div className="row d-flex align-items-center" style={{ width: 80, minHeight: 50 }}>
              <span className={btnClasses} style={btnStyle} onClick={() => this.cancelInsurance(originalRow)}>
                close
              </span>
            </div>
          );
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: "price",
      label: "price",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ maxWidth: 100 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "note",
      label: "note",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ minWidth: 200 }}>
              <span>{parse(data.replaceAll("\n", "<br/>"))}</span>
            </div>
          );
        },
      },
    },
    {
      name: "addedBy",
      label: "addedBy",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ maxWidth: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
  ];

  options = {
    filterType: "dropdown",
    fixedHeader: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    responsive: "standard",
    elevation: 0,
    download: false,
    print: false,
    viewColumns: false,
    sortOrder: {
      name: "id",
      direction: "desc",
    },
  };
  getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableCell: {
          head: {
            zIndex: "0 !important",
          },
        },
      },
    });

  TabContent = ({ children }) => {
    return <div className="mt-2 p-20">{children}</div>;
  };

  handlePriceChange = (e) => {
    const price = e.target.value;
    this.setState({ price });
  };

  handleNoteChange = (e) => {
    const note = e.target.value;
    this.setState({ note });
  };

  isDisabled = () => {
    return this.state.note.length === 0 || this.state.price <= 0;
  };

  closeSweetAlert = () => {
    if (this.state.showSuccessAlert) {
      this.setState({ showSuccessAlert: false, price: 0, note: "" });
    } else {
      this.setState({ showFailedAlert: false });
    }
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    const payload = {
      cashier: 2,
      price: this.state.price,
      note: this.state.note,
    };
    api
      .post("/receipts/insurance/create", payload)
      .then((response) => {
        global.debugPrinter(response.data);
        if (response.data > 0) {
          this.setState({ loading: false, showSuccessAlert: true });
          this.loadInsurances();
        } else {
          this.setState({ loading: false, showFailedAlert: true });
        }
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };

  cancelInsurance = (data) => {
    this.setState({ loading: true });
    api
      .get("/receipts/insurance/cancel/" + data.id)
      .then((response) => {
        global.debugPrinter("Cancel insurance", response.data);
        var filtered = this.state.data;
        if (response.data) {
          filtered = filtered.filter((row) => row.id !== data.id);
        }
        this.setState({ loading: false, data: filtered });
        this.loadInsurances();
        // this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };

  handleTabClick = (event, value) => {
    this.setState({ pageNum: value });
  };

  render() {
    const TabContent = this.TabContent;
    const data = this.state.data;

    return (
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="Transaction-table-wrap Tab-wrap">
            <SweetAlert success show={this.state.showSuccessAlert} title="Success" onConfirm={this.closeSweetAlert} />
            <SweetAlert danger show={this.state.showFailedAlert} title="Failed" onConfirm={this.closeSweetAlert} />
            <RctCard>
              {this.state.loading && <RctSectionLoader />}
              <RctCardContent>
                <AppBar position="static" color="default">
                  <SwipeableViews>
                    <Tabs
                      value={this.state.pageNum}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={this.handleTabClick}
                    >
                      <Tab label="List" />
                      <Tab label="Create" />
                    </Tabs>
                  </SwipeableViews>
                </AppBar>
                <form>
                  <SwipeableViews index={this.state.pageNum}>
                    <TabContent>
                      <ThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                          components={{ TableToolbar: () => <div /> }}
                          theme={this.theme}
                          className="table table-striped "
                          data={data}
                          columns={this.columns}
                          options={this.options}
                        />
                      </ThemeProvider>
                    </TabContent>
                    {this.state.pageNum === 1 ? (
                      <TabContent>
                        <div className="col-lg-4 col-sm-12 col-md-4 w-xs-half-block">
                          <FormControl className="mb-2" fullWidth>
                            <Label htmlFor="comment">Price: </Label>
                            <Input
                              type="number"
                              id="adornment-comment"
                              value={this.state.price}
                              onChange={this.handlePriceChange}
                            />
                          </FormControl>
                        </div>

                        <div className="col-lg-12 col-sm-12 col-md-6 w-xs-half-block">
                          <FormControl fullWidth>
                            <Label htmlFor="comment">Note: </Label>
                            <Input
                              type="textarea"
                              id="adornment-comment"
                              value={this.state.note}
                              onChange={this.handleNoteChange}
                              style={{ height: 180 }}
                            />
                          </FormControl>
                        </div>

                        <div className="d-flex flex-row-reverse justify-content-between">
                          <button
                            className="btn btn-primary mt-4"
                            onClick={this.handleSubmit}
                            type="button"
                            disabled={this.isDisabled()}
                          >
                            Submit
                          </button>
                        </div>
                      </TabContent>
                    ) : (
                      <TabContent />
                    )}
                  </SwipeableViews>
                </form>
              </RctCardContent>
            </RctCard>
          </div>
        </div>
      </div>
    );
  }
}
