import React, { Component } from "react";
import { RctCard, RctCardContent } from "components/RctCard";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";
import api from "api";
import { compareAsc, compareDesc, parse } from "date-fns";

export default class index extends Component {
  state = {};
  componentDidMount() {
    global.printPageName("Receipts/ViewTemporary");
    this.setState({ loading: true });
    api
      .get("/receipts/temp/list")
      .then((response) => {
        global.debugPrinter(response.data);
        this.setState({ loading: false });
        this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  }

  columns = [
    {
      name: "id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "90px" } }),
        customBodyRender: (data, type, row) => {
          let originalRow = this.state.data.find((o) => {
            return o.id === data;
          });
          const btnStyle = {
            MozUserSelect: "none",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            OUserSelect: "none",
            userSelect: "none",
            cursor: "pointer",
          };
          const btnClasses = "material-icons text-secondary mr-1";
          return (
            <div className="row d-flex align-items-center justify-content-center" style={{ width: 60, minHeight: 50 }}>
              <span className={btnClasses} style={btnStyle} onClick={() => this.completePayment(originalRow)}>
                done
              </span>
              <span className={btnClasses} style={btnStyle} onClick={() => this.handleCancelBooking(originalRow)}>
                close
              </span>
            </div>
          );
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "chalet",
      label: "Chalet",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
    {
      name: "period",
      label: "Period",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 200 }}>
              <span>{data}</span>
            </div>
          );
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            var date1 = obj1.data;
            date1 = date1.substring(0, date1.indexOf(" "));
            var date2 = obj2.data;
            date2 = date2.substring(0, date2.indexOf(" "));
            const dateLeft = parse(date1, "dd/MM/yyyy", new Date());
            const dateRight = parse(date2, "dd/MM/yyyy", new Date());
            return order === "asc" ? compareAsc(dateLeft, dateRight) : compareDesc(dateLeft, dateRight);
          };
        },
      },
    },
    {
      name: "cashier",
      label: "Cashier",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <div style={{ width: 150 }}>
              <span>{data}</span>
            </div>
          );
        },
      },
    },
  ];

  options = {
    filterType: "dropdown",
    fixedHeader: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    responsive: "standard",
    elevation: 0,
    download: false,
    print: false,
    viewColumns: false,
    sortOrder: {
      name: "period",
      direction: "desc",
    },
  };

  getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableCell: {
          head: {
            zIndex: "0 !important",
          },
        },
      },
    });

  completePayment = (data) => {
    global.debugPrinter("complete payment", data.id);
    const url = "/receipts/create?id=" + data.id + "&complete=true";
    this.props.history.push(url);
  };

  handleCancelBooking = (data) => {
    global.debugPrinter("handleCancelBooking", data.id);
    this.setState({ loading: true });

    api
      .get("/receipts/temp/cancel/" + data.id)
      .then((response) => {
        global.debugPrinter("Cancel booking", response.data);
        var filtered = this.state.data;
        if (response.data) {
          filtered = filtered.filter((row) => row.id !== data.id);
        }
        this.setState({ loading: false, data: filtered });
        // this.setState(response.data);
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
    // Call Api to cancel booking refetch data or remove it from the array
  };
  render() {
    const data = this.state.data;
    const { loading } = this.state;
    return (
      <div>
        <RctCard>
          {loading && <RctSectionLoader />}
          <RctCardContent noPadding>
            <ThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable
                theme={this.theme}
                className="table table-striped "
                title={"Temporary Receipts"}
                data={data}
                columns={this.columns}
                options={this.options}
              />
            </ThemeProvider>
          </RctCardContent>
        </RctCard>
      </div>
    );
  }
}
