/**
 * App Reducers
 */
import { combineReducers } from "redux";
import settings from "./settings";
//import chatAppReducer from "./ChatAppReducer";
//import emailAppReducer from "./EmailAppReducer";
import sidebarReducer from "./SidebarReducer";

const reducers = combineReducers({
  settings,
  //  chatAppReducer,
  //  emailApp: emailAppReducer,
  sidebar: sidebarReducer,
});

export default reducers;
