import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import QueueAnim from "rc-queue-anim";

export default class NotFound extends Component {
  render() {
    return (
      <QueueAnim type="bottom" duration={2000}>
        <div className="error-wrapper" key="1" style={{ height: "100vh", width: "100vw", zIndex: 10000 }}>
          <div className="session-inner-wrapper">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-9 mx-auto">
                <div className="error-body text-center">
                  <h2 className="oops">Oops.. </h2>
                  <h2 className="bold mb-0">404</h2>
                  <h2 className="error-msg mb-30">Sorry, page not found</h2>
                  <h4 className="mb-50">
                    Looks like you have navigated too far from Federation Space. Our Application <br />
                    cannot help you here.
                  </h4>
                  <Button component={Link} to="/" variant="contained" className="btn-light btn-lg">
                    Go To Home Page
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </QueueAnim>
    );
  }
}
