/**
 * User Block Component
 */
import {
  TOKEN,
  USERNAME,
  EMAIL,
  DISPLAY_NAME,
  DISPLAY_PICTURE,
  USER_ID,
  SUPER_ADMIN,
} from "constants/LocalStorageConfig";
import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// components

class UserBlock extends Component {
  state = {
    userDropdownMenu: false,
  };

  /**
   * Logout User
   */
  logoutUser = () => {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(USERNAME);
    localStorage.removeItem(EMAIL);
    localStorage.removeItem(DISPLAY_NAME);
    localStorage.removeItem(DISPLAY_PICTURE);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(SUPER_ADMIN);
    window.location.reload();
  };

  /**
   * Toggle User Dropdown Menu
   */
  toggleUserDropdownMenu() {
    this.setState({ userDropdownMenu: !this.state.userDropdownMenu });
  }

  render() {
    return (
      <div className="top-sidebar">
        <div className="sidebar-user-block">
          <Dropdown
            isOpen={this.state.userDropdownMenu}
            toggle={() => this.toggleUserDropdownMenu()}
            className="rct-dropdown"
          >
            <DropdownToggle tag="div" className="d-flex align-items-center">
              <div className="user-profile">
                <img
                  src={localStorage.getItem(DISPLAY_PICTURE)}
                  alt="user profile"
                  className="img-fluid rounded-circle"
                  width="50"
                  height="50"
                  style={{
                    width: 50,
                    height: 50,
                    objectFit: "cover",
                  }}
                />
              </div>
              <div className="user-info">
                <span className="user-name ml-4">{localStorage.getItem(USERNAME)}</span>
                <i className="zmdi zmdi-chevron-down dropdown-icon mx-4"></i>
              </div>
            </DropdownToggle>
            <DropdownMenu>
              <ul className="list-unstyled mb-0">
                <li className="p-15 border-bottom user-profile-top bg-primary rounded-top">
                  <p className="text-white mb-0 fs-14">{localStorage.getItem(DISPLAY_NAME)}</p>
                  <span className="text-white fs-14">{localStorage.getItem(EMAIL)}</span>
                </li>
                <li onClick={this.logoutUser} className="d-flex p-2">
                  <i className="zmdi zmdi-power text-danger mr-3"></i>
                  <span className="text-primary ">Log Out</span>
                </li>
              </ul>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default UserBlock;
