import React, { useContext } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import ReactJson from "react-json-view";
import { deepParseJson } from "deep-parse-json";

function LoggerModal({ showModal, req, res, dismiss }) {
  return (
    <Modal isOpen={showModal} toggle={dismiss} fullscreen={true} style={{ maxWidth: "1600px", width: "80%" }}>
      <ModalHeader toggle={dismiss}>Choose Chalet</ModalHeader>
      <ModalBody>
        <div className="  align-items-start ">
          <div className="col-12  ">
            <h1>Request</h1>
            <ReactJson src={req && deepParseJson(req)} displayDataTypes={false} collapsed={true} />
          </div>
          <br />
          <div className="col-12 ">
            <h1>Response</h1>
            <ReactJson src={res && deepParseJson(res)} displayDataTypes={false} collapsed={true} />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default LoggerModal;
