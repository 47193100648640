//Comments Pages
import CreateComment from "routes/Comments/Create";
import ViewComment from "routes/Comments/View";

const routes = [
  //Dashboard
  {
    path: "create",
    component: CreateComment,
  },
  {
    path: "view",
    component: ViewComment,
  },
];

export default routes;
