import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import { RctCard, RctCardContent } from "components/RctCard";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import RctSectionLoader from "components/RctSectionLoader/RctSectionLoader";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  isSunday,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
  nextSaturday,
  nextWednesday,
  nextSunday,
  format,
  parse,
} from "date-fns";
import { nextThursday } from "date-fns/esm";
import BasicInformationForm from "../BasicInformationForm";
import PaymentDetails from "../PaymentDetails";
import PreviewReceipt from "../PreviewReceipt";
import ChaletDetails from "../ChaletDetails";
import Note from "../Note";
import api from "api";
import { SUPER_ADMIN } from "constants/LocalStorageConfig";

const filter = createFilterOptions();

/**
 * discount in-line
 * Add early option and in-line amount
 */
export default class index extends Component {
  state = {
    pageNum: 0,
    maxSteps: 0,
    id: 0,
    editID: 0,
    cashierName: "",
    customerInfo: { name: "", mobile: "" },
    customers: [],
    availableChalets: [],
    isDiscount: false,
    paymentType: "knet",
    discountReason: "",
    fullPrice: 400,
    insurancePrice: 100,
    depositPrice: 100,
    note: "",
    loading: false,
    isEdit: false,
    isComplete: false,
    showSuccessAlert: false,
    showFailedAlert: false,
  };

  initiateState = (reload = false) => {
    this.setState({
      pageNum: 0,
      maxSteps: 0,
      id: 0,
      editID: 0,
      cashierName: "",
      customerInfo: { name: "", mobile: "" },
      customers: [],
      availableChalets: [],
      isDiscount: false,
      paymentType: "knet",
      discountReason: "",
      fullPrice: 400,
      insurancePrice: 100,
      depositPrice: 100,
      note: "",
      loading: true,
      isEdit: false,
      isComplete: false,
      showSuccessAlert: false,
      showFailedAlert: false,
    });
    var queryID = null;
    var isComplete = null;
    var isEdit = null;
    if (!reload) {
      queryID = new URLSearchParams(this.props.location.search).get("id");
      isComplete = new URLSearchParams(this.props.location.search).get("complete");
      isEdit = new URLSearchParams(this.props.location.search).get("edit");
    }
    api
      .get("/receipts/create")
      .then((response) => {
        // handle success
        global.debugPrinter(response.data);
        this.setState(response.data);
        if (isEdit) {
          this.getEditApi(queryID);
        } else if (isComplete) {
          this.getCompleteApi(queryID);
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };
  componentDidMount() {
    global.printPageName("Receipts/Create");
    this.initiateState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.triggerPropertySectionChange) {
      this.updatePrice();
    }

    const pState = { ...prevState };
    pState.pageNum = 0;
    pState.maxSteps = 0;
    const cState = { ...this.state };
    cState.pageNum = 0;
    cState.maxSteps = 0;
    if (JSON.stringify(cState) !== JSON.stringify(pState)) {
      this.setState({ maxSteps: this.state.pageNum });
    }
  }

  getEditApi = (queryID) => {
    api // get edit
      .get("/receipts/edit/" + queryID + "/tracked")
      .then((response) => {
        // handle success
        global.debugPrinter("tracked", response.data);
        response.data.fromDate = parse(response.data.fromDate, "dd/MM/yyyy", new Date());
        response.data.toDate = parse(response.data.toDate, "dd/MM/yyyy", new Date());
        response.data.isEdit = true;
        this.setState(response.data);
      })
      .catch(function (error) {
        // handle error
        global.debugPrinter(error);
      })
      .then(() => {
        this.setState({ loading: false });
      });
  };
  getCompleteApi = (queryID) => {
    api // get complete
      .get("/receipts/edit/" + queryID + "/temporary")
      .then((response) => {
        // handle success
        global.debugPrinter("temporary", response.data);
        response.data.fromDate = parse(response.data.fromDate, "dd/MM/yyyy", new Date());
        response.data.toDate = parse(response.data.toDate, "dd/MM/yyyy", new Date());
        response.data.isComplete = true;

        this.setState(response.data);
      })
      .catch(function (error) {
        // handle error
        global.debugPrinter(error);
      })
      .then(() => {
        this.setState({ loading: false });
      });
  };

  getNumberOfWeekDays(start, end, dayNum) {
    if (!start || !end) return 0;
    // Sunday's num is 0 with Date.prototype.getDay.
    var days = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
    dayNum = days[dayNum.toLowerCase().substr(0, 3)];
    // Calculate the number of days between start and end.
    var daysInInterval = Math.ceil((end.getTime() - start.getTime()) / (1000 * 3600 * 24));
    // Calculate the nb of days before the next target day (e.g. next Sunday after start).
    var toNextTargetDay = (7 + dayNum - start.getDay()) % 7;
    // Calculate the number of days from the first target day to the end.
    var daysFromFirstTargetDay = Math.max(daysInInterval - toNextTargetDay, 0);
    // Calculate the number of weeks (even partial) from the first target day to the end.
    return Math.ceil(daysFromFirstTargetDay / 7);
  }

  updatePrice = () => {
    const numOfWeekDays = this.getNumberOfWeekDays(this.state.fromDate, this.state.toDate, "sunday");
    const numOfWeekends = this.getNumberOfWeekDays(this.state.fromDate, this.state.toDate, "thursday");
    const numOfProperties = this.getPropertiesLength();

    const fullPrice =
      (numOfWeekDays * this.state?.weekdayPrice + numOfWeekends * this.state?.weekendPrice) * numOfProperties;

    this.setState({ fullPrice, triggerPropertySectionChange: false });
  };

  getPropertiesLength = () => {
    return this.state.availableChalets.length === 0
      ? 0
      : this.state.availableChalets
          .filter((chalet) => chalet.selected)[0]
          .available.filter((property) => {
            return property.selected;
          }).length;
  };
  handlePageChange = (event, value) => {
    const maxSteps = value >= this.state.maxSteps ? value : this.state.maxSteps;
    this.setState({ pageNum: value, maxSteps });
  };
  TabContent = ({ children }) => {
    return <div className="mt-2 p-20">{children}</div>;
  };

  handleCustomerNameChange = (event, newValue) => {
    var customerInfo = newValue;
    if (typeof newValue === "string") {
      customerInfo = {
        name: newValue,
        mobile: this.state.customerInfo?.mobile,
      };
    } else if (newValue && newValue?.inputValue) {
      // Create a new value from the user input
      customerInfo = {
        name: newValue.inputValue,
        mobile: this.state.customerInfo?.mobile,
      };
    }
    if (customerInfo === null) {
      customerInfo = {
        name: "",
        mobile: "",
      };
    }
    this.setState({ customerInfo });
  };

  handleCustomerMobileChange = (event, newValue) => {
    var customerInfo = newValue;
    if (typeof newValue === "string") {
      customerInfo = {
        name: this.state.customerInfo?.name,
        mobile: newValue,
      };
    } else if (newValue && newValue?.inputValue) {
      // Create a new value from the user input
      customerInfo = {
        name: this.state.customerInfo?.name,
        mobile: newValue.inputValue,
      };
    }
    if (customerInfo === null) {
      customerInfo = {
        name: "",
        mobile: "",
      };
    }
    this.setState({ customerInfo });
  };

  //this could be moved as an internal function
  handleCustomerNameFilterOptions = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.title);
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        name: inputValue,
        mobile: this.state.customerInfo?.mobile,
      });
    }

    return filtered;
  };
  //this could be moved as an internal function
  handleCustomerMobileFilterOptions = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.title);
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        name: this.state.customerInfo?.name,
        mobile: inputValue,
      });
    }

    return filtered;
  };

  handleCustomerNameGetOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.name;
  };

  handleCustomerMobileGetOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.mobile;
  };

  handleSelectChalet = (index) => {
    const availableChalets = [...this.state.availableChalets];
    for (let i = 0; i < availableChalets.length; i++) {
      availableChalets[i].selected = false;
      for (let j = 0; j < availableChalets[i].available.length; j++) {
        availableChalets[i].available[j].selected = false;
      }
    }
    availableChalets[index].selected = true;
    this.setState({ availableChalets, triggerPropertySectionChange: true });
  };

  handleSelectChaletProperty = (index) => {
    const availableChalets = [...this.state.availableChalets];
    const chalet = availableChalets.filter((chalet) => chalet.selected)[0];
    chalet.available[index].selected = !chalet.available[index].selected;
    this.setState({ availableChalets, triggerPropertySectionChange: true });
  };
  handleFromDateChange = (date) => {
    let fromDate = date;
    let toDateExtraLabel = null;
    let fromDateExtraLabel = null;

    if (localStorage.getItem(SUPER_ADMIN) !== `true`) {
      if (isMonday(fromDate) || isTuesday(fromDate) || isTuesday(fromDate) || isWednesday(fromDate)) {
        fromDate = nextThursday(fromDate);
      } else if (isFriday(fromDate) || isSaturday(fromDate)) {
        fromDate = nextSunday(fromDate);
      }
      if (fromDate !== date) {
        fromDateExtraLabel = `Date have changed from ${format(date, "dd/MM/yyyy")} to ${format(
          fromDate,
          "dd/MM/yyyy"
        )}`;
      }
    }

    let toDate = this.state.toDate || date;
    if (isSunday(fromDate)) {
      toDate = nextWednesday(fromDate);
    } else if (isThursday(fromDate)) {
      toDate = nextSaturday(fromDate);
    }
    this.setState({ fromDate, toDate, fromDateExtraLabel, toDateExtraLabel, triggerPropertySectionChange: true });
  };
  handleToDateChange = (date) => {
    if (localStorage.getItem(SUPER_ADMIN) === `true`) {
      this.setState({ toDate: date });
      return;
    }
    let toDate = date;
    let toDateExtraLabel = null;
    if (isSunday(toDate) || isMonday(toDate) || isTuesday(toDate)) {
      toDate = nextWednesday(toDate);
      toDateExtraLabel = `Date have changed from ${format(date, "dd/MM/yyyy")} to ${format(toDate, "dd/MM/yyyy")}`;
    } else if (isThursday(toDate) || isFriday(toDate)) {
      toDate = nextSaturday(toDate);
      toDateExtraLabel = `Date have changed from ${format(date, "dd/MM/yyyy")} to ${format(toDate, "dd/MM/yyyy")}`;
    }
    this.setState({ toDate, toDateExtraLabel, triggerPropertySectionChange: true });
  };

  handleDiscountHandle = () => {
    this.setState({ isDiscount: !this.state.isDiscount });
  };

  handlePaymentTypeChange = () => {
    let paymentType = this.state.paymentType === "knet" ? "cash" : "knet";
    this.setState({ paymentType });
  };

  handleEditNumberAmount = (field) => (event) => {
    const val = event.target.value;

    const val2 = /^\d+$/.test(val) && val?.match(/\d/g)?.join("");
    if (val2) {
      const updateState = { ...this.state };
      updateState[field] = val2.replace(/^0+/, "");
      if (updateState[field] === "") {
        updateState[field] = "0";
      }
      this.setState(updateState);
    }
  };

  handleEditText = (field) => (event) => {
    const updateState = { ...this.state };
    updateState[field] = event.target.value;
    this.setState(updateState);
  };

  handleTabClick = (event, value) => {
    if (value <= this.state.maxSteps) {
      this.handlePageChange(event, value);
    }
  };

  handleEditDiscountReason = (event) => {
    this.setState({ discountReason: event.target.value });
  };

  handleGetChalet = () => {
    return this.state.availableChalets.filter((chalet) => chalet.selected)[0].name;
  };

  handleGetProperties = () => {
    return this.state.availableChalets
      .filter((chalet) => chalet.selected)[0]
      .available.filter((property) => property.selected)
      .map((res) => res.name)
      .join(" ");
  };

  handleSubmitRequest = () => {
    this.setState({ loading: true });
    const allInfo = this.state;
    const payload = {
      cashier: 2, //allInfo.cashierName,
      customerInfo: allInfo.customerInfo,
      extraNote: allInfo.note,
      payment: {
        full: allInfo.fullPrice,
        insurance: allInfo.insurancePrice,
        deposit: allInfo.depositPrice,
        type: allInfo.paymentType,
        discountReason: allInfo.discountReason,
      },
      bookingDetails: {
        chalet: this.handleGetChalet(),
        properties: this.handleGetProperties(),
        fromDate: format(allInfo.fromDate, "dd/MM/yyyy"),
        toDate: format(allInfo.toDate, "dd/MM/yyyy"),
      },
    };
    global.debugPrinter("payload = ", payload);
    var url = "/create";
    if (allInfo.isEdit) {
      url = "/edit/" + allInfo.id + "/tracked";
    } else if (allInfo.isComplete) {
      url = "/edit/" + allInfo.id + "/temporary";
    }
    api
      .post("/receipts" + url, payload)
      .then((response) => {
        global.debugPrinter(response.data);
        if (response.data > 0) {
          this.setState({ loading: false, showSuccessAlert: true });
        } else {
          this.setState({ loading: false, showFailedAlert: true });
        }
      })
      .catch((error) => {
        // handle error
        global.debugPrinter(error);
        this.setState({ loading: false });
      });
  };

  closeSweetAlert = () => {
    if (this.state.showSuccessAlert) {
      window.history.pushState({ path: "/receipts/create" }, "", "/receipts/create");
      this.initiateState(true);
      this.setState({ showSuccessAlert: false });
    } else {
      this.setState({ showFailedAlert: false });
    }
  };

  render() {
    const TabContent = this.TabContent;
    return (
      <div>
        <SweetAlert success show={this.state.showSuccessAlert} title="Success" onConfirm={this.closeSweetAlert} />
        <SweetAlert danger show={this.state.showFailedAlert} title="Failed" onConfirm={this.closeSweetAlert} />

        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="Transaction-table-wrap Tab-wrap">
              <RctCard>
                {this.state.loading && <RctSectionLoader />}
                <RctCardContent>
                  <AppBar position="static" color="default">
                    <SwipeableViews>
                      <Tabs
                        value={this.state.pageNum}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleTabClick}
                      >
                        <Tab label="Customer" />
                        <Tab label="Property" />
                        <Tab label="Payment" />
                        <Tab label="Note" />
                        <Tab label="Preview" />
                      </Tabs>
                    </SwipeableViews>
                  </AppBar>

                  <form>
                    <SwipeableViews index={this.state.pageNum}>
                      <TabContent>
                        <h1>Step 1 / 5</h1>
                        <BasicInformationForm
                          id={this.state.id}
                          cashierName={this.state.cashierName}
                          customerInfo={this.state.customerInfo}
                          customers={this.state.customers}
                          onChangeCustomerName={this.handleCustomerNameChange}
                          OnCustomerNameFilterOptions={this.handleCustomerNameFilterOptions}
                          OnCustomerNameGetOptionLabel={this.handleCustomerNameGetOptionLabel}
                          onChangeCustomerMobile={this.handleCustomerMobileChange}
                          OnCustomerMobileFilterOptions={this.handleCustomerMobileFilterOptions}
                          OnCustomerMobileGetOptionLabel={this.handleCustomerMobileGetOptionLabel}
                          onPageChange={this.handlePageChange}
                        />
                      </TabContent>
                      <TabContent>
                        <h1>Step 2 / 5</h1>
                        <ChaletDetails
                          availableChalets={this.state.availableChalets}
                          fromDate={this.state.fromDate}
                          fromDateExtraLabel={this.state.fromDateExtraLabel}
                          toDateExtraLabel={this.state.toDateExtraLabel}
                          toDate={this.state.toDate}
                          onSelectChalet={this.handleSelectChalet}
                          onSelectChaletProperty={this.handleSelectChaletProperty}
                          onFromDateChange={this.handleFromDateChange}
                          onToDateChange={this.handleToDateChange}
                          onPageChange={this.handlePageChange}
                        />
                      </TabContent>
                      <TabContent>
                        <h1>Step 3 / 5</h1>
                        <PaymentDetails
                          onPageChange={this.handlePageChange}
                          paymentType={this.state.paymentType}
                          onEditDiscountReason={this.handleEditText("discountReason")}
                          onPaymentTypeChange={this.handlePaymentTypeChange}
                          onDiscount={this.handleDiscountHandle}
                          isDiscount={this.state.isDiscount || this.state.discountReason.length !== 0}
                          discountReason={this.state.discountReason}
                          fullPrice={this.state.fullPrice}
                          insurancePrice={this.state.insurancePrice}
                          depositPrice={this.state.depositPrice}
                          onEditFullPrice={this.handleEditNumberAmount("fullPrice")}
                          onEditInsurancePrice={this.handleEditNumberAmount("insurancePrice")}
                          onEditDepositPrice={this.handleEditNumberAmount("depositPrice")}
                        />
                      </TabContent>
                      <TabContent>
                        <h1>Step 4 / 5</h1>
                        <Note
                          onNoteChange={this.handleEditText("note")}
                          value={this.state.note}
                          onPageChange={this.handlePageChange}
                        />
                      </TabContent>
                      {this.state.pageNum === 4 ? (
                        <TabContent>
                          <h1>Step 5 / 5</h1>
                          <PreviewReceipt
                            onPageChange={this.handlePageChange}
                            allInfo={this.state}
                            getChalet={this.handleGetChalet}
                            getProperties={this.handleGetProperties}
                            submitRequest={this.handleSubmitRequest}
                          />
                        </TabContent>
                      ) : (
                        <TabContent />
                      )}
                    </SwipeableViews>
                  </form>
                </RctCardContent>
              </RctCard>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
