//Dashboard Pages
import Dashboard from "routes/dashboard";
import Details from "routes/dashboard/details";

const routes = [
  //Dashboard
  {
    path: "index",
    component: Dashboard,
  },
  {
    path: "details",
    component: Details,
  },
];

export default routes;
