import axios from "axios";
import AppConfig from "constants/AppConfig";
import { TOKEN } from "constants/LocalStorageConfig";

const instance = axios.create({
  baseURL: AppConfig.apiUrl,
  timeout: 20000,
  headers: {
    Authorization: localStorage.getItem(TOKEN),
  },
});

export default instance;
